* {
  margin: 0;
  padding: 0;
  font-family: poppins;
  font-size: 18px;
  font-weight: 500;
}

.logo {
  max-height: 40px;
}

.navbar-collapse {
  position: fixed;
  padding-top: 120px;
  padding-left: 30px;
  text-align: start;
  top: 0;
  left: -100%;
  height: 100%;
  width: 250px;
  background-color: blue;
  transition: left 0.3s ease;
  z-index: 1045;
}

.navbar-collapse.show {
  left: 0;
}

.navbar-toggler {
  z-index: 1050;
}

body.nav-open {
  overflow: hidden;
}

/* Nav.Link custom styles */
.navbar-dark .basic-navbar-nav .nav-link {
  color: white !important;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: lightgray;
}

/* Adjustments for large screens */
@media (min-width: 992px) {
  .navbar-collapse {
    position: static;
    padding-top: 0;
    padding-left: 0;
    height: auto;
    width: auto;
    background-color: transparent;
    left: 0;
  }

  .navbar-collapse.show {
    left: 0;
  }
}
/* Custom CSS for sticky navbar */
.sticky-top {
  background-color: rgb(0, 0, 119);
  background-image: url('../assets/images/testimonial-bg.png');
  position: sticky;
  top: 0;
  z-index: 1045; /* Ensures the navbar stays on top of other content */
}

/* Optional: Adjust text color if needed */
.sticky-top .navbar-nav .nav-link {
  color: white;
}

.sticky-top .navbar-nav .nav-link:hover {
  color: lightgray;
}