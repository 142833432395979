@import url("https://fonts.googleapis.com/css2?family=Yantramanav:wght@100;300;400;500;700;900&display=swap");
:root {
  --font-default: "Yantramanav", sans-serif;
  --fontawesome: "Font Awesome 5 Pro";
  --black: #000000;
  --dark: #03112d;
  --dark-secondary: #001d4c;
  --dark-optional: #3e00a7;
  --white: #ffffff;
  --color-primary: #0c5adb;
  --color-secondary: #3f1399;
  --color-optional: rgb(255, 30, 126);
  --color-heading: #04000b;
  --color-paragraph: #787878;
  --box-shadow-primary: -1px 3px 10px 0 rgba(0, 0, 0, 0.6);
  --box-shadow-secondary: 0 10px 30px 0 rgba(44, 130, 237, 0.4);
  --box-shadow-regular: 0px 2px 12px 0px #e7e7e7;
  --bg-gray: #f3f7fd;
  --bg-gradient: linear-gradient(
    30deg,
    var(--color-secondary) 20%,
    var(--color-primary) 80%
  );
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body,
html {
  height: 100%;
}
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}
html,
body,
div,
span,
img,
ol,
ul,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
option,
legend,
label,
table,
th,
td,
tr,
article,
aside,
caption,
figure,
footer,
header,
hgroup,
mark,
nav,
section,
time,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}
.wrapper {
  height: 100%;
}
img {
  border: none;
  outline: none;
  max-width: 100%;
}
label {
  display: inline-block;
  font-weight: normal;
  margin-bottom: 5px;
  max-width: 100%;
}
a {
  outline: none;
  text-decoration: none;
  color: var(--color-heading);
}
a img {
  border: none;
}
a:active {
  outline: none;
  text-decoration: none;
  color: var(--color-heading);
  opacity: 1;
}
a:focus {
  outline: none;
  text-decoration: none;
  color: var(--color-heading);
}
a:hover {
  outline: none;
  text-decoration: none;
  color: var(--color-primary);
  opacity: 1;
}
button {
  outline: medium none;
}
iframe {
  border: none;
}
hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid #eeeeee;
}
pre {
  display: block;
  margin: 0 0 30px;
  padding: 9.5px;
  word-wrap: break-word;
  word-break: break-all;
  color: #333333;
  border: 1px solid #ededed;
  border-radius: inherit;
  background-color: #f9f9f9;
  font-size: 13px;
  line-height: 1.42857143;
}
input {
  border: 1px solid #e7e7e7;
  border-radius: inherit;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  min-height: 50px;
}
input:focus {
  outline: none;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}
textarea:focus {
  outline: none;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}
select:focus {
  outline: none;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}
ul {
  list-style-type: none;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
b {
  font-weight: 900;
}
strong {
  font-weight: 900;
}
.row {
  --bs-gutter-x: 30px;
}
body {
  font-family: var(--font-default);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  overflow-x: hidden;
  background-color: var(--white);
  color: var(--color-paragraph);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-heading);
  font-weight: normal;
  line-height: 1.2;
  font-family: var(--font-default);
  letter-spacing: 0;
  margin: 0;
  margin-bottom: 15px;
}
h1 {
  font-size: 40px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 27px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 14px;
}
a,
.btn,
button {
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  outline: medium none;
  text-decoration: none;
  font-weight: 600;
}
p {
  color: var(--color-paragraph);
  margin: 0 0 15px;
  text-transform: none;
  font-weight: 400;
  font-family: var(--font-default);
  line-height: 1.7;
}
@media (min-width: 1250px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1250px;
  }
}
.container-full {
  padding: 0 15px;
  margin: auto;
  max-width: 1400px;
}
@media (min-width: 576px) {
  .container-full {
    max-width: 540px;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container-full {
    max-width: 720px;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .container-full {
    max-width: 960px;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .container-full {
    max-width: 95%;
    width: 95%;
  }
}
.container-fill {
  padding: 0 15px;
  margin: auto;
  max-width: 100%;
}
@media (min-width: 576px) {
  .container-fill {
    max-width: 540px;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container-fill {
    max-width: 720px;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .container-fill {
    max-width: 960px;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .container-fill {
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 1199px) {
  .container-fill {
    padding: 0 15px;
    width: 100%;
  }
}
@media only screen and (min-width: 1367px) {
  .container-stage {
    margin-left: calc((100% - 1320px) / 2);
    width: auto;
    min-width: auto;
    max-width: inherit;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .row {
    --bs-gutter-x: 1.5rem;
  }
}
.bg-cover {
  background-position: center center !important;
  background-size: cover !important;
}
.bg-fixed {
  background-attachment: fixed !important;
  background-position: center center !important;
  background-size: cover !important;
}
.bg-fit {
  background-size: 100% 100% !important;
  background-position: center !important;
}
.bg-gray {
  background: var(--bg-gray);
}
.bg-gray-mixed {
  background: rgba(0, 0, 0, 0)
    linear-gradient(to bottom, var(--white) 0%, var(--bg-gray) 100%) repeat
    scroll 0 0;
}
.bg-light {
  background-color: var(--white);
}
.bg-dark {
  background-color: var(--dark) !important;
}
.bg-theme {
  background-color: var(--color-primary);
}
.bg-gradient {
  background-color: var(--color-primary);
  background: var(--bg-gradient) !important;
  background-size: 220% 150% !important;
}
.text-light {
  color: var(--white);
}
.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5,
.text-light h6,
.text-light p,
.text-light span,
.text-light a {
  color: var(--white);
}
.shadow {
  -webkit-box-shadow: inherit !important;
  box-shadow: inherit !important;
}
.shadow.dark {
  position: relative;
  z-index: 1;
}
.shadow.dark:after {
  background: var(--dark);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.shadow.dark-hard {
  position: relative;
  z-index: 1;
}
.shadow.dark-hard:after {
  background: rgba(0, 4, 30, 0) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.65;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.shadow.theme {
  position: relative;
  z-index: 1;
}
.shadow.theme:after {
  background: var(--color-primary) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.shadow.light {
  position: relative;
  z-index: 1;
}
.shadow.light:after {
  background: var(--white) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.default-padding,
.default-padding-top,
.default-padding-bottom,
.default-padding-mx {
  position: relative;
}
.default-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media only screen and (max-width: 767px) {
  .default-padding {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.default-padding-big {
  padding: 250px 0;
}
@media only screen and (max-width: 767px) {
  .default-padding-big {
    padding: 50px 0;
  }
}
.default-padding-top {
  padding-top: 120px;
}
@media only screen and (max-width: 767px) {
  .default-padding-top {
    padding-top: 50px;
  }
}
.default-padding-bottom {
  padding-bottom: 120px;
}
@media only screen and (max-width: 767px) {
  .default-padding-bottom {
    padding-bottom: 50px;
  }
}
.default-padding.bottom-less {
  padding-top: 120px;
  padding-bottom: 90px;
}
@media only screen and (max-width: 767px) {
  .default-padding.bottom-less {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}
.default-padding-bottom.bottom-less {
  margin-bottom: -30px;
}
@media only screen and (max-width: 767px) {
  .default-padding-bottom.bottom-less {
    margin-bottom: 0;
  }
}
.default-padding-top.bottom-less {
  margin-bottom: -30px;
}
@media only screen and (max-width: 767px) {
  .default-padding-top.bottom-less {
    margin-bottom: -20px;
  }
}
@media only screen and (min-width: 1200px) {
  .align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.relative {
  position: relative;
}
.overflow-hidden {
  overflow: hidden;
}
.border-top {
  border-top: 1px solid #e7e7e7;
}
.border-bottom {
  border-bottom: 1px solid #e7e7e7;
}
.border-left {
  border-left: 1px solid #e7e7e7;
}
.border-right {
  border-right: 1px solid #e7e7e7;
}
.heading {
  font-weight: 600;
  margin-bottom: 25px;
  margin-top: -5px;
  font-size: 50px;
  line-height: 1.1;
}
.heading span {
  font-weight: 400;
}
.site-heading {
  margin-bottom: 60px;
}
.title {
  font-weight: 600;
  font-size: 50px;
  line-height: 1.1;
}
.site-heading .title {
  margin-bottom: 0;
}
.sub-title {
  text-transform: uppercase;
  color: var(--color-primary);
  font-weight: 500;
  background: var(--bg-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  position: relative;
  z-index: 1;
}
.bg-gradient .sub-title,
.bg-theme .sub-title {
  color: var(--white);
  -webkit-background-clip: inherit;
  background-clip: inherit;
  -webkit-text-fill-color: inherit;
  background: transparent;
}
.site-heading p {
  margin-bottom: 0;
  margin-top: 25px;
  padding: 0 10%;
}
.site-heading .devider {
  display: inline-block;
  width: 50px;
  height: 2px;
  background: var(--color-primary);
  position: relative;
  z-index: 1;
  left: 10px;
}
.site-heading.light .devider,
.bg-theme .site-heading .devider,
.bg-gradient .site-heading .devider,
.shadow .site-heading .devider,
.bg-dark .site-heading .devider {
  background: var(--white);
}
.site-heading .devider:before {
  position: absolute;
  left: -15px;
  top: 0;
  content: "";
  height: 2px;
  width: 10px;
  background: var(--color-primary);
}
.site-heading.light .devider:before,
.bg-theme .site-heading .devider:before,
.bg-gradient .site-heading .devider:before,
.shadow .site-heading .devider:before,
.bg-dark .site-heading .devider:before {
  background: var(--white);
}
@media (max-width: 767px) {
  .site-heading p {
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .site-heading {
    margin-bottom: 30px;
  }
}
.heading-left {
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
}
.heading-left .heading {
  font-weight: 600;
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .heading-left .heading {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .heading-left .heading {
    margin-bottom: 15px;
  }
}
.heading-left p {
  margin-bottom: 0;
  font-size: 18px;
}
.heading-left .btn {
  margin-top: 30px;
}
@media only screen and (max-width: 767px) {
  .heading-left {
    margin-bottom: 30px;
  }
}
.bg-dark .heading-left p {
  opacity: 0.8;
}
.heading-left .content-left {
  border-right: 1px solid #e7e7e7;
  padding-right: 35px;
}
@media only screen and (max-width: 991px) {
  .heading-left .content-left {
    border: none;
    padding: 0;
    margin-bottom: 30px;
  }
}
.sub-heading {
  text-transform: uppercase;
  color: var(--color-primary);
  font-weight: 500;
  background: var(--bg-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 280% 150%;
  display: inline-block;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}
.bg-gradient .sub-heading {
  color: var(--white);
  background: transparent;
  -webkit-background-clip: inherit;
  background-clip: inherit;
  -webkit-text-fill-color: inherit;
  opacity: 0.9;
}
.video-play-button {
  display: inline-block;
  background: var(--color-primary);
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  color: var(--color-heading);
  position: relative;
}
.video-play-button i {
  font-weight: 400;
  font-size: 20px;
  position: relative;
  left: 3px;
  top: 2px;
}
.video-play-button .effect {
  position: absolute;
  width: 100px;
  height: 100px;
  background: var(--color-primary);
  opacity: 0;
  border-radius: 100%;
  left: 50%;
  top: 50%;
  z-index: -1;
  -webkit-animation: video-play 1500ms ease-out infinite;
  animation: video-play 1500ms ease-out infinite;
}
.video-play-button .effect::after {
  position: absolute;
  content: "";
  width: 70px;
  height: 70px;
  background: var(--color-primary);
  opacity: 0;
  border-radius: 100%;
  left: 50%;
  top: 50%;
  z-index: -1;
  -webkit-animation: video-play 1500ms ease-out infinite;
  animation: video-play 1500ms ease-out infinite;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.video-play-button .effect::before {
  position: absolute;
  content: "";
  width: 60px;
  height: 60px;
  background: var(--color-secondary);
  opacity: 0;
  border-radius: 100%;
  left: 50%;
  top: 50%;
  z-index: -1;
  -webkit-animation: video-play 1500ms ease-out infinite;
  animation: video-play 1500ms ease-out infinite;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.video-play-button.with-text {
  width: auto;
  background: transparent;
}
.video-play-button.with-text span {
  position: relative;
  z-index: 1;
}
.video-play-button.with-text span i {
  margin-left: 25px;
  margin-right: 30px;
  color: var(--white);
  left: -1px;
}
.video-play-button.with-text .effect {
  height: 60px;
  width: 60px;
  left: 0;
  -webkit-animation: inherit;
  animation: inherit;
  background: var(--color-primary);
  top: 0;
  opacity: 1;
  z-index: 1;
}
.video-play-button.with-text .effect::before {
  display: none;
}
.video-play-button.with-text .effect::after {
  background: var(--color-primary);
  height: 100%;
  width: 100%;
  z-index: inherit;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.video-play-button.with-text.optional .effect {
  background: var(--white);
}
.video-play-button.with-text.optional .effect::after {
  background: var(--white);
}
.video-play-button.with-text.optional span i {
  color: var(--color-primary);
}
@-webkit-keyframes video-play {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
@keyframes video-play {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.zoom-animation {
  -webkit-animation: zoom 2000ms ease-out infinite;
  animation: zoom 2000ms ease-out infinite;
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.2, 1.2);
  }
}
@keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }
}
.spiner-animation {
  -webkit-animation: spinner 20s infinite linear;
  animation: spinner 20s infinite linear;
}
@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}
@keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}
.spiner-reverse-animation {
  -webkit-animation: spinnerReverse 20s infinite linear;
  animation: spinnerReverse 20s infinite linear;
}
@-webkit-keyframes spinnerReverse {
  to {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
}
@keyframes spinnerReverse {
  to {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
}
@keyframes UpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
.updown-animation {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-name: UpDown;
  animation-name: UpDown;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
@-webkit-keyframes UpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
.leftRight-animation {
  -webkit-animation: fadeLeftRight 10s ease-out infinite;
  animation: fadeLeftRight 10s ease-out infinite;
}
@keyframes fadeLeftRight {
  0%,
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
@-webkit-keyframes fadeLeftRight {
  0%,
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
.rightLeft-animation {
  -webkit-animation: fadeRightLeft 10s ease-out infinite;
  animation: fadeRightLeft 10s ease-out infinite;
}
@keyframes fadeRightLeft {
  0%,
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
  }
}
@-webkit-keyframes fadeRightLeft {
  0%,
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
  }
}
.zoomUpDown-animation {
  -webkit-animation: zoomUpDown 10s ease-out infinite;
  animation: zoomUpDown 10s ease-out infinite;
}
@keyframes zoomUpDown {
  0%,
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  50% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}
@-webkit-keyframes zoomUpDown {
  0%,
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
.moving-animation {
  -webkit-animation: moving 10s ease-out infinite;
  animation: moving 10s ease-out infinite;
}
@-webkit-keyframes moving {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  20% {
    -webkit-transform: translate(0px, -30px);
    transform: translate(0px, -30px);
  }
  50% {
    -webkit-transform: translate(-100px, -30px);
    transform: translate(-100px, -30px);
  }
  70% {
    -webkit-transform: translate(-100px, 0px);
    transform: translate(-100px, 0px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
@keyframes moving {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  20% {
    -webkit-transform: translate(0px, -30px);
    transform: translate(0px, -30px);
  }
  50% {
    -webkit-transform: translate(-100px, -30px);
    transform: translate(-100px, -30px);
  }
  70% {
    -webkit-transform: translate(-100px, 0px);
    transform: translate(-100px, 0px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
@keyframes infinite-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes infinite-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes open {
  0% {
    width: 0;
    height: 0;
  }
  50% {
    width: 100px;
    height: 0;
  }
  100% {
    width: 100px;
    height: 55px;
  }
}
@keyframes open {
  0% {
    width: 0;
    height: 0;
  }
  50% {
    width: 100px;
    height: 0;
  }
  100% {
    width: 100px;
    height: 55px;
  }
}
@-webkit-keyframes openB {
  0% {
    width: 0px;
  }
  100% {
    width: 100px;
  }
}
@keyframes openB {
  0% {
    width: 0px;
  }
  100% {
    width: 100px;
  }
}
@-webkit-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}
@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 500;
  text-transform: capitalize;
  transition: all 0.35s ease-in-out;
  overflow: hidden;
  border-radius: 8px;
  border: 2px solid transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 16px;
  letter-spacing: 0;
  padding: 13px 30px;
  background: #e7edf8;
  position: relative;
  z-index: 1;
}
.btn:focus,
.btn.active {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  border: 2px solid transparent;
}
.btn:hover {
  background-color: var(--color-primary);
  color: var(--white);
}
.btn.radius {
  border-radius: 6px;
}
.btn.btn-border {
  border: 2px solid #e7e7e7;
  background: transparent;
}
.btn.btn-border:hover {
  background: var(--color-primary);
  border-color: var(--color-primary);
}
.btn.btn-border.animation::after {
  position: absolute;
  top: 0;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  height: 100%;
  width: 0;
  z-index: -1;
  background: #e7e7e7;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.btn.btn-border.animation:hover {
  border-color: #e7e7e7;
  color: var(--dark);
}
.btn.btn-border.animation:hover::after {
  width: 100%;
}
.btn.btn-border-dark {
  border: 2px solid var(--dark);
  background: transparent;
}
.btn.btn-border-dark:hover {
  background: var(--dark);
  border-color: var(--dark);
}
.btn.btn-border-dark.animation::after {
  position: absolute;
  top: 0;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  height: 100%;
  width: 0;
  z-index: -1;
  background: var(--dark);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.btn.btn-border-dark.animation:hover {
  border-color: var(--dark);
  color: var(--white);
}
.btn.btn-border-dark.animation:hover::after {
  width: 100%;
}
.btn.btn-border-theme {
  border: 2px solid var(--color-primary);
  background: transparent;
}
.btn.btn-border-theme:hover {
  background: var(--color-primary);
  border-color: var(--color-primary);
}
.btn.btn-border-theme.animation::after {
  position: absolute;
  top: 0;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  height: 100%;
  width: 0;
  z-index: -1;
  background: var(--color-primary);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.btn.btn-border-theme.animation:hover {
  border-color: var(--color-primary);
  color: var(--white);
}
.btn.btn-border-theme.animation:hover::after {
  width: 100%;
}
.btn.btn-border-light {
  border: 2px solid var(--white);
  background: transparent;
  color: var(--white);
}
.btn.btn-border-light:hover {
  background: var(--white);
  border-color: var(--white);
  color: var(--dark);
}
.btn.btn-border-light.animation::after {
  position: absolute;
  top: 0;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  height: 100%;
  width: 0;
  z-index: -1;
  background: var(--white);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.btn.btn-border-light.animation:hover {
  border-color: var(--white);
  color: var(--dark);
}
.btn.btn-border-light.animation:hover::after {
  width: 100%;
}
.btn.btn-gradient {
  border: none;
  color: var(--white);
}
.btn.btn-gradient::after {
  position: absolute;
  top: 0;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: linear-gradient(
    to right,
    var(--color-primary),
    var(--color-secondary),
    var(--color-primary)
  );
  background-size: 220% 150%;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.btn.btn-gradient:hover::after {
  background-position: -70% 0;
}
.btn.btn-gradient.active {
  background-position: -70% 0;
}
.btn.btn-theme {
  color: var(--white);
  border: none;
  background: var(--color-primary);
}
.btn.btn-theme::after {
  position: absolute;
  top: inherit;
  right: inherit;
  bottom: inherit;
  left: -5%;
  content: "";
  height: 150px;
  width: 115%;
  z-index: -1;
  background-color: var(--dark);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-transform: translateY(-45%) skew(25deg) scale(0);
  transform: translateY(-45%) skew(25deg) scale(0);
}
.btn.btn-theme:hover::after {
  -webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
  transform: translateY(-45%) skew(25deg) scale(1.2);
}
.btn.btn-theme.secondary {
  color: var(--white);
  border: none;
  background: linear-gradient(
    90deg,
    var(--color-optional) 0%,
    rgb(255, 100, 92) 100%
  );
}
.btn.btn-theme.secondary::after {
  position: absolute;
  top: inherit;
  right: inherit;
  bottom: inherit;
  left: -5%;
  content: "";
  height: 150px;
  width: 115%;
  z-index: -1;
  background-color: var(--dark);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-transform: translateY(-45%) skew(25deg) scale(0);
  transform: translateY(-45%) skew(25deg) scale(0);
}
.text-light .btn.btn-theme.secondary::after {
  background-color: var(--white);
}
.text-light .btn.btn-theme.secondary:hover {
  color: var(--color-heading);
}
.btn.btn-theme.secondary:hover::after {
  -webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
  transform: translateY(-45%) skew(25deg) scale(1.2);
}
.btn.btn-theme.hover-light::after {
  background-color: var(--white);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.btn.btn-theme.hover-light:hover {
  color: var(--dark);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.btn.btn-theme.hover-light:hover::after {
  -webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
  transform: translateY(-45%) skew(25deg) scale(1.2);
}
.btn.btn-light {
  color: var(--color-heading);
  border: none;
  background: var(--white);
  border: 2px solid transparent;
}
.btn.btn-light.btn-md {
  padding: 13px 52px;
}
.btn.btn-light:hover {
  color: var(--white);
  background: transparent;
  border: 2px solid var(--white);
}
.btn.btn-dark {
  color: var(--white);
  background: var(--dark);
  border: none;
}
.btn.btn-dark::after {
  position: absolute;
  top: 0;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  height: 100%;
  width: 0;
  z-index: -1;
  background-color: var(--color-primary);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.shadow .btn.btn-dark::after {
  background-color: var(--white);
}
.shadow .btn.btn-dark:hover {
  color: var(--color-heading) !important;
}
.btn.btn-dark:hover::after {
  width: 100%;
}
.btn.btn-dark.secondary {
  color: var(--white);
  border: none;
  background: var(--dark-optional);
}
.btn.btn-dark.secondary::after {
  position: absolute;
  top: inherit;
  right: inherit;
  bottom: inherit;
  left: -5%;
  content: "";
  height: 150px;
  width: 115%;
  z-index: -1;
  background-color: var(--white);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-transform: translateY(-45%) skew(25deg) scale(0);
  transform: translateY(-45%) skew(25deg) scale(0);
}
.btn.btn-dark.secondary:hover::after {
  -webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
  transform: translateY(-45%) skew(25deg) scale(1.2);
}
.btn.btn-dark.secondary:hover {
  color: var(--color-heading);
}
.btn i {
  position: relative;
  top: 1px;
  margin-left: 5px;
}
.btn.text-slide {
  min-width: 150px;
  height: 55px;
  line-height: 55px;
}
.btn.text-slide span {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
}
.btn.animate-border {
  overflow: inherit;
  z-index: inherit;
  width: 200px;
  height: 55px;
  line-height: 55px;
  padding: 0;
}
.btn.animate-border:hover {
  background: transparent;
  color: var(--dark);
}
.btn.animate-border:hover .hover-border::after,
.btn.animate-border:hover .hover-border::before {
  opacity: 1;
  -webkit-animation: open 0.4s;
  animation: open 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  -webkit-animation-direction: normal;
  animation-direction: normal;
}
.btn.animate-border:hover .hover-border-bottom::after,
.btn.animate-border:hover .hover-border-bottom::before {
  opacity: 1;
  -webkit-animation: openB 0.4s;
  animation: openB 0.4s;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  -webkit-animation-direction: normal;
  animation-direction: normal;
}
.btn.animate-border .hover-border {
  position: absolute;
  left: 0;
  top: -1px;
  height: 100%;
  width: 100%;
}
.btn.animate-border .hover-border::before {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  display: block;
  opacity: 0;
  border-top: solid 2px var(--color-primary);
  border-left: solid 2px var(--color-primary);
  right: 98px;
  top: -1px;
}
.btn.animate-border .hover-border::after {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  display: block;
  opacity: 0;
  border-top: solid 2px var(--color-primary);
  border-right: solid 2px var(--color-primary);
  left: 98px;
  top: -1px;
}
.btn.animate-border .hover-border-bottom {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.btn.animate-border .hover-border-bottom::before {
  position: absolute;
  content: "";
  width: 0;
  display: block;
  opacity: 0;
  height: 55px;
  border-bottom: solid 2px var(--color-primary);
  right: -1px;
  bottom: -2px;
}
.btn.animate-border .hover-border-bottom::after {
  position: absolute;
  content: "";
  width: 0;
  display: block;
  opacity: 0;
  height: 100%;
  border-bottom: solid 2px var(--color-primary);
  left: 0;
  bottom: -2px;
}
.btn.animated-arrow {
  border: none;
  background: transparent;
  min-width: 14rem;
  height: auto;
  padding: 0;
}
.btn.animated-arrow .circle {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3.5rem;
  height: 3.5rem;
  background: var(--color-primary);
  border-radius: 2rem;
}
.btn.animated-arrow .circle .icon {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 15px;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.btn.animated-arrow .circle .icon::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid var(--white);
  border-right: 0.125rem solid var(--white);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.btn.animated-arrow .button-text {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 0;
  margin: 0 0 0 70px;
  color: var(--dark);
  line-height: 2;
  text-align: left;
  width: 100%;
  font-size: 14px;
}
.btn.animated-arrow:hover {
  color: var(--white);
}
.btn.animated-arrow:hover .circle {
  width: 100%;
}
.btn.animated-arrow:hover .circle .icon.arrow {
  background: var(--white);
  -webkit-transform: translate(1.5rem, 0);
  transform: translate(1.5rem, 0);
}
.btn.animated-arrow:hover .button-text {
  color: var(--white);
}
.btn.circle {
  border-radius: 30px !important;
}
.btn-simple {
  line-height: 1;
  text-transform: uppercase;
  display: inline-block;
  color: var(--color-paragraph);
}
.btn-simple i {
  transform: rotate(-45deg);
  display: inline-block;
  margin-left: 2px;
}
.btn-simple:hover {
  color: var(--color-primary);
}
.video-btn i {
  display: inline-block;
  height: 55px;
  width: 55px;
  text-align: center;
  line-height: 55px;
  background: var(--color-primary);
  border-radius: 50%;
  position: relative;
  margin-right: 20px;
  color: var(--white);
}
.text-light .video-btn i {
  background: var(--white);
  color: var(--color-primary);
}
.video-btn i::after {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: var(--color-primary) repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
  z-index: -1;
}
.text-light .video-btn i::after {
  background: var(--white) repeat scroll 0 0;
}
.text-shine {
  background: linear-gradient(
    to right,
    var(--dark) 0,
    var(--white) 10%,
    var(--color-primary) 20%
  );
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: shine 3s infinite linear;
  animation: shine 3s infinite linear;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
  padding: 12px 48px;
}
.animate-inout {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#8a6552),
    to(#472d20)
  );
  background: linear-gradient(90deg, #8a6552 0%, #472d20 100%);
  color: var(--white);
  font-size: 12px;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border: solid 2px var(--color-primary);
  cursor: pointer;
}
.btn-icon {
  position: relative;
  padding-left: 20px;
}
.btn-icon:hover {
  color: var(--color-primary);
}
.btn-icon::after {
  position: absolute;
  right: 10px;
  top: 50%;
  content: "";
  height: 2px;
  left: 0;
  background: var(--color-heading);
  transform: translateY(-50%);
  margin-top: -1px;
  transition: all 0.35s ease-in-out;
}
.btn-icon:hover::after {
  background: var(--color-primary);
}
.btn-icon i {
  font-weight: 500;
}
.btn-icon:hover {
  padding-left: 40px;
}
.animate-inout span {
  z-index: 1;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  line-height: 20px;
}
.animate-inout::before,
.animate-inout::after {
  width: 0%;
  height: 0%;
  position: absolute;
  content: "";
  border-radius: 100%;
}
.animate-inout:after {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: var(--white);
}
.animate-inout:before {
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  background: var(--color-primary);
}
.animate-inout.animate-inout-borde {
  background: transparent;
  color: var(--dark);
}
.animate-inout.animate-inout-borde::after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#8a6552),
    to(#472d20)
  );
  background: linear-gradient(90deg, #8a6552 0%, #472d20 100%);
}
.animate-inout.animate-inout-borde:hover {
  color: var(--white);
}
.animate-inout.animate-inout-borde:hover::before {
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}
.animate-inout.animate-inout-borde:hover::after {
  -webkit-transition: all 0.7s ease-in;
  transition: all 0.7s ease-in;
}
.animate-inout:hover {
  color: var(--dark);
}
.animate-inout:hover::before,
.animate-inout:hover::after {
  width: 200px;
  height: 200px;
  border-radius: 4px;
}
.animate-inout:hover::before {
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}
.animate-inout:hover::after {
  -webkit-transition: all 0.7s ease-in;
  transition: all 0.7s ease-in;
}
.btn-md {
  padding: 16px 52px;
  font-size: 17px;
}
.btn-sm {
  padding: 16px 50px;
  font-size: 14px;
}
.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: var(--white);
  border: solid 1px #e8e8e8;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 16px;
  font-weight: normal;
  height: 50px;
  line-height: 50px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 15px;
}
.nice-select:hover {
  border-color: #dbdbdb;
}
.nice-select:active,
.nice-select.open,
.nice-select:focus {
  border-color: #999999;
}
.nice-select::after {
  border-bottom: 2px solid #999999;
  border-right: 2px solid #999999;
  content: "";
  display: block;
  height: 5px;
  margin-top: -6px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 5px;
}
.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
  overflow-y: auto !important;
  height: auto;
}
.nice-select.open ::after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.nice-select.disabled {
  border-color: #ededed;
  color: #999999;
  pointer-events: none;
}
.nice-select.disabled::after {
  border-color: #96aac1;
}
.nice-select.wide {
  width: 100%;
}
.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}
.nice-select.right {
  float: right;
}
.nice-select.right .list {
  left: auto;
  right: 0;
}
.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}
.nice-select.small::after {
  height: 4px;
  width: 4px;
}
.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}
.nice-select .list {
  background-color: var(--white);
  border-radius: 5px;
  -webkit-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
  width: 100%;
}
.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}
.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #f6f6f6;
}
.nice-select .option.selected {
  font-weight: 600;
}
.nice-select .option.disabled {
  background-color: transparent;
  color: #999999;
  cursor: default;
}
.no-csspointerevents .nice-select .list {
  display: none;
}
.no-csspointerevents .nice-select.open .list {
  display: block;
}
.breadcrumb-area {
  padding: 150px 0;
  word-break: break-word;
  padding-top: 180px;
  background-color: #d1eeec;
  overflow: hidden;
  background-color: #d1eeec;
  background-size: 30%, contain;
  background-repeat: no-repeat;
  background-position: top center;
}
.breadcrumb-area.thumb-less {
  padding-top: 180px;
}
.breadcrumb-area .breadcrumb {
  background: transparent none repeat scroll 0 0;
  border: none;
  display: inline-block;
  margin: 0;
  position: relative;
  z-index: 1;
  padding: 0;
  text-align: center;
  margin-top: 15px;
}
.breadcrumb-area h1 {
  display: block;
  font-weight: 600;
  margin-top: -10px;
  margin-bottom: 0;
}
.breadcrumb > li + li::before {
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  padding: 0 5px;
  color: #ffffff;
  display: none !important;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: none;
}
.breadcrumb-area .breadcrumb li i {
  margin-right: 5px;
  font-weight: 100;
}
.breadcrumb-area .breadcrumb li {
  padding: 0 15px;
  position: relative;
  display: inline-block;
  z-index: 1;
  font-weight: 600;
}
.breadcrumb-area .breadcrumb li::after {
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  font-weight: 500;
  right: -5px;
  position: absolute;
  font-size: 18px;
  line-height: 0;
  top: 50%;
  margin-top: -1px;
}
.breadcrumb-area .breadcrumb li:last-child::after {
  display: none;
}
.breadcrumb-area .breadcrumb a,
.breadcrumb-area .breadcrumb li {
  font-weight: 500;
  color: var(--color-heading);
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .breadcrumb-area {
    padding-top: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .breadcrumb-area {
    padding: 80px 0 !important;
  }
  .breadcrumb-area h1 {
    font-size: 36px;
  }
}
nav.woocommerce-breadcrumb a {
  font-weight: 500;
}
.breadcrumb-area.thumb-less::after {
  display: none;
}
.breadcrumb-area.thumb-less {
  background: #d1eeec;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.breadcrumb-area.thumb-less::before {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  height: 600px;
  width: 600px;
  background: var(--color-primary);
  z-index: -1;
  margin-left: -300px;
  border-radius: 50%;
  opacity: 0.06;
  transform: translateY(-50%);
}
.banner-area {
  height: 100%;
  width: 100%;
  position: relative;
}
@media (max-width: 1023px) {
  .banner-area {
    height: auto;
  }
}
.banner-area div {
  height: 100%;
}
.banner-area div.swiper-slide .row div {
  height: auto;
}
.banner-area.top-pad-80 .content {
  padding-top: 80px;
}
@media (max-width: 1023px) {
  .banner-area.top-pad-80 .content {
    padding-top: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-area.top-pad-80 .content {
    padding-top: 140px;
  }
}
.banner-area.top-pad-90 .content {
  padding-top: 90px;
}
@media (max-width: 1023px) {
  .banner-area.top-pad-90 .content {
    padding-top: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-area.top-pad-90 .content {
    padding-top: 140px;
  }
}
.banner-area.top-pad-100 .content {
  padding-top: 100px;
}
@media (max-width: 1023px) {
  .banner-area.top-pad-100 .content {
    padding-top: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-area.top-pad-100 .content {
    padding-top: 140px;
  }
}
.banner-area.top-pad-110 .content {
  padding-top: 110px;
}
@media (max-width: 1023px) {
  .banner-area.top-pad-110 .content {
    padding-top: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-area.top-pad-110 .content {
    padding-top: 140px;
  }
}
.banner-area.top-pad-120 .content {
  padding-top: 120px;
}
@media (max-width: 1023px) {
  .banner-area.top-pad-120 .content {
    padding-top: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-area.top-pad-120 .content {
    padding-top: 140px;
  }
}
.banner-area.top-pad-130 .content {
  padding-top: 130px;
}
@media (max-width: 1023px) {
  .banner-area.top-pad-130 .content {
    padding-top: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-area.top-pad-130 .content {
    padding-top: 140px;
  }
}
.banner-area.top-pad-150 .content {
  padding-top: 150px;
}
@media (max-width: 1023px) {
  .banner-area.top-pad-150 .content {
    padding-top: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-area.top-pad-150 .content {
    padding-top: 140px;
  }
}
.banner-area.auto-height {
  height: auto;
}
.banner-area.auto-height div {
  height: auto;
}
.banner-area.auto-height .content {
  padding: 200px 0;
}
@media only screen and (max-width: 767px) {
  .banner-area.auto-height .content {
    padding: 60px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.auto-height .content {
    padding: 120px 0;
  }
}
.banner-area.auto-height .content .thumb {
  padding-left: 35px;
}
@media (max-width: 991px) {
  .banner-area.auto-height .content .thumb {
    padding-left: 0;
    margin-top: 50px;
  }
}
.banner-area.auto-height.inc-header-transparent .content {
  padding-top: 250px;
}
@media only screen and (max-width: 767px) {
  .banner-area.auto-height.inc-header-transparent .content {
    padding-top: 140px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.auto-height.inc-header-transparent .content {
    padding-top: 220px;
  }
}
.banner-area.auto-height.inc-header-transparent.double-items.bottom-thumb
  .content
  .info {
  padding-top: 80px;
}
.banner-area.auto-height.inc-header-transparent.double-items.bottom-thumb
  .content
  .thumb {
  margin-top: 250px;
}
@media only screen and (max-width: 767px) {
  .banner-area.auto-height.inc-header-transparent.double-items.bottom-thumb
    .content
    .thumb {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.auto-height.inc-header-transparent.double-items.bottom-thumb
    .content
    .thumb {
    margin-top: 50px;
  }
}
.banner-area .content {
  position: relative;
  z-index: 9;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
@media (max-width: 1023px) {
  .banner-area .content {
    padding: 120px 0;
  }
}
@media only screen and (max-width: 767px) {
  .banner-area .content {
    padding: 60px 0;
  }
}
.banner-area h4 {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transform: translate3d(-15%, 0, 0);
  transform: translate3d(-15%, 0, 0);
  opacity: 0;
  visibility: hidden;
}
.banner-area h2 {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
}
.banner-area p {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  opacity: 0;
  padding-right: 25%;
  margin: 0;
  visibility: hidden;
}
@media (max-width: 1023px) {
  .banner-area p {
    padding-right: 0;
  }
}
.banner-area.text-center p {
  padding-left: 13%;
  padding-right: 13%;
}
@media (max-width: 1023px) {
  .banner-area.text-center p {
    padding: 0;
  }
}
.banner-area .thumb {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  opacity: 0;
  visibility: hidden;
}
.banner-area .button {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  opacity: 0;
  visibility: hidden;
  margin-top: 30px;
}
.banner-area .banner-slide h4 {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.banner-area .banner-slide h2 {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.banner-area .banner-slide p {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.banner-area .banner-slide .button {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.banner-area .banner-slide .swiper-slide.swiper-slide-active h4 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1200ms;
  transition-delay: 1200ms;
}
.banner-area .banner-slide .swiper-slide.swiper-slide-active h2 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1400ms;
  transition-delay: 1400ms;
}
.banner-area .banner-slide .swiper-slide.swiper-slide-active p {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1600ms;
  transition-delay: 1600ms;
}
.banner-area .banner-slide .swiper-slide.swiper-slide-active .button {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1800ms;
  transition-delay: 1800ms;
}
.banner-area.double-items .thumb {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.banner-area.double-items .swiper-slide.swiper-slide-active .thumb {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 500ms;
  transition-delay: 500ms;
  visibility: visible;
  opacity: 1;
}
.banner-area.double-items.bottom-thumb .content {
  padding: 0;
}
.banner-area.double-items.bottom-thumb .content .thumb {
  margin-top: 120px;
}
@media only screen and (max-width: 767px) {
  .banner-area.double-items.bottom-thumb .content {
    padding: 50px 0;
  }
  .banner-area.double-items.bottom-thumb .content .thumb {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.double-items.bottom-thumb .content {
    padding: 120px 0;
  }
  .banner-area.double-items.bottom-thumb .content .thumb {
    margin-top: 50px;
  }
}
.banner-area.zoom-effect .banner-thumb {
  -webkit-transition: 10s ease-out;
  transition: 10s ease-out;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
}
.banner-area.zoom-effect .swiper-slide.swiper-slide-active .banner-thumb {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.banner-area .swiper-notification {
  display: none;
}
.banner-area .swiper-slide.swiper-slide-active h4 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 800ms;
  transition-delay: 800ms;
}
.banner-area .swiper-slide.swiper-slide-active h2 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 1600ms;
  transition-delay: 1600ms;
}
.banner-area .swiper-slide.swiper-slide-active p {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 2400ms;
  transition-delay: 2400ms;
}
.banner-area .swiper-slide.swiper-slide-active .button {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 3200ms;
  transition-delay: 3200ms;
}
.banner-area.banner-style-one .swiper-slide.swiper-slide-active .button {
  -webkit-transition-delay: 2400ms;
  transition-delay: 2400ms;
}
.banner-area .banner-items {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}
.banner-area:hover .swiper-button-prev,
.banner-area:hover .swiper-button-next {
  opacity: 1;
}
.banner-area:hover .swiper-button-prev {
  left: 30px;
  right: auto;
}
.banner-area:hover .swiper-button-next {
  right: 30px;
  left: auto;
}
.banner-area .swiper-button-prev,
.banner-area .swiper-button-next {
  height: auto;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  opacity: 0;
}
.banner-area .swiper-button-prev::after,
.banner-area .swiper-button-next::after {
  font-size: 28px;
  color: var(--white);
}
.banner-area.navigation-circle .swiper-button-prev,
.banner-area.navigation-circle .swiper-button-next {
  height: 60px;
  width: 60px;
  line-height: 60px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}
.banner-area.navigation-circle .swiper-button-prev::after,
.banner-area.navigation-circle .swiper-button-next::after {
  font-size: 18px;
}
.banner-area.navigation-right-bottom .swiper-button-prev,
.banner-area.navigation-right-bottom .swiper-button-next {
  left: auto;
  right: 30px;
  opacity: 1;
  top: auto;
  -webkit-transform: inherit;
  transform: inherit;
  bottom: 50px;
  border-radius: inherit;
}
.banner-area.navigation-right-bottom .swiper-button-prev {
  right: 92px;
}
.banner-area.navigation-between-bottom .content {
  padding-bottom: 105px;
}
@media only screen and (min-width: 829px) and (max-width: 1023px) {
  .banner-area.navigation-between-bottom .content {
    padding-bottom: 220px;
  }
}
.banner-area.navigation-between-bottom .swiper-button-prev,
.banner-area.navigation-between-bottom .swiper-button-next {
  left: auto;
  right: 30px;
  opacity: 1;
  top: auto;
  -webkit-transform: inherit;
  transform: inherit;
  bottom: 30px;
  border-radius: inherit;
}
.banner-area.navigation-between-bottom .swiper-button-prev {
  left: 30px;
  right: auto;
}
@media only screen and (max-width: 830px) {
  .banner-area .swiper-button-prev,
  .banner-area .swiper-button-next {
    display: none;
  }
}
.banner-area.navigation-custom .swiper-button-prev::after {
  font-family: "ElegantIcons";
  content: "\23";
  font-size: 22px;
}
.banner-area.navigation-custom .swiper-button-next::after {
  font-family: "ElegantIcons";
  content: "\24";
  font-size: 22px;
}
.banner-area.navigation-text .swiper-button-prev,
.banner-area.navigation-text .swiper-button-next {
  opacity: 1;
  top: auto;
  -webkit-transform: inherit;
  transform: inherit;
  left: auto;
  right: 0;
  bottom: 0;
  display: inline-block;
  width: 150px;
  background: rgba(0, 0, 0, 0.2);
  text-align: center;
  height: 100px;
  line-height: 100px;
}
@media only screen and (max-width: 830px) {
  .banner-area.navigation-text .swiper-button-prev,
  .banner-area.navigation-text .swiper-button-next {
    display: none;
  }
}
.banner-area.navigation-text .swiper-button-prev {
  right: 151px;
}
.banner-area.navigation-text .swiper-button-prev::after {
  font-family: var(--font-default);
  content: "Prev";
  text-transform: uppercase !important;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.6px;
}
.banner-area.navigation-text .swiper-button-next::after {
  font-family: var(--font-default);
  content: "Next";
  text-transform: uppercase !important;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.6px;
}
.banner-area.navigation-icon-solid .swiper-button-prev::after,
.banner-area.navigation-icon-solid .swiper-button-next::after {
  font-size: 30px;
}
.banner-area.navigation-custom-large .swiper-button-prev,
.banner-area.navigation-custom-large .swiper-button-next {
  min-width: 65px;
  height: 30px;
  margin: 0;
}
.banner-area.navigation-custom-large .swiper-button-prev::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f104";
  font-weight: 100;
  font-size: 30px;
  position: absolute;
  left: 8px;
}
.banner-area.navigation-custom-large .swiper-button-prev::before {
  position: absolute;
  top: 50%;
  right: inherit;
  bottom: inherit;
  left: 12px;
  content: "";
  height: 2px;
  width: 50px;
  z-index: -1;
  background-color: var(--white);
  margin-top: -1px;
}
.banner-area.navigation-custom-large .swiper-button-next::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f105";
  font-weight: 100;
  font-size: 30px;
  position: absolute;
  right: 8px;
}
.banner-area.navigation-custom-large .swiper-button-next::before {
  position: absolute;
  top: 50%;
  right: 12px;
  bottom: inherit;
  left: inherit;
  content: "";
  height: 2px;
  width: 50px;
  z-index: -1;
  background-color: var(--white);
  margin-top: -1px;
}
.banner-area.navigation-right-botom .swiper-button-prev,
.banner-area.navigation-right-botom .swiper-button-next {
  position: absolute;
  left: auto;
  right: 30px;
  top: auto;
  bottom: 40px;
  opacity: 1;
  -webkit-transform: inherit;
  transform: inherit;
}
.banner-area.navigation-right-botom .swiper-button-prev {
  right: 60px;
}
.banner-area.navigation-right-botom.navigation-custom-large .swiper-button-prev,
.banner-area.navigation-right-botom.navigation-custom-large
  .swiper-button-next {
  bottom: 30px;
}
.banner-area.navigation-right-botom.navigation-custom-large
  .swiper-button-prev {
  right: 100px;
}
.banner-area .swiper-pagination {
  height: auto;
  bottom: 35px;
}
.banner-area .swiper-pagination span.swiper-pagination-bullet {
  height: 4px;
  width: 50px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
  border-radius: inherit;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.banner-area
  .swiper-pagination
  span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: white;
  height: 7px;
}
@media only screen and (max-width: 767px) {
  .banner-area.include-pagination .content {
    padding-bottom: 100px;
  }
}
.banner-area .swiper-pagination-fraction span {
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.banner-area .swiper-pagination-fraction span.swiper-pagination-current {
  font-size: 30px;
  font-family: var(--font-default);
  font-weight: 600;
}
.banner-style-one-area {
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.shape-bottom-center {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  z-index: -1;
}
.shape-top-right {
  position: absolute;
  right: 0;
  top: 0;
  height: 600px;
  width: 600px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right top;
  z-index: -1;
}
.shape-left-top {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
  background-position: top left;
}
.banner-style-one-area .shape-left-top {
  opacity: 0.3;
}
.banner-style-one .content {
  padding-top: 200px;
  padding-bottom: 50px;
}
.banner-style-one .content .info {
  padding-bottom: 150px;
}
.banner-style-one .content .thumb img {
  max-width: 120%;
}
.banner-style-one .content .thumb {
  margin-bottom: -20px;
}
.banner-style-one a {
  margin-right: 25px;
  display: inline-block;
}
.banner-style-one .button {
  margin-top: 35px;
}
.btn-regular {
  border-bottom: 1px solid;
  line-height: 1;
}
.banner-style-one h4 {
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 20px;
}
.banner-style-one h2 {
  font-weight: 500;
  font-size: 60px;
  line-height: 1;
  margin-bottom: 30px;
}
.banner-style-two-area {
  position: relative;
  height: 100%;
  z-index: 1;
}
.banner-style-two h4 {
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 20px;
  display: inline-block;
}
.banner-style-two-area .shape-bottom-center {
  width: 100%;
  background-size: cover;
  height: 100%;
}
.banner-style-area div {
  height: 100%;
}
.banner-style-two-area .row > div {
  height: auto;
}
.banner-style-two-area div {
  height: 100%;
}
.banner-style-two .thumb {
  max-width: 110%;
  float: right;
  margin-top: 90px;
  position: relative;
  z-index: 1;
  height: auto;
}
.banner-style-two .thumb img {
  max-width: 120%;
}
.banner-style-two {
  position: relative;
  z-index: 1;
}
.round-animation {
  position: absolute;
  top: 0;
  left: -100px;
  margin-top: -50px;
  z-index: -1;
  background: transparent;
  width: 200px;
  height: 200px !important;
  border: 8px solid rgba(98, 34, 204, 0.06);
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
  transition-property: all;
  -webkit-transition-property: color, background, border-color;
  transition-property: color, background, border-color;
}
.round-animation::after,
.round-animation::before {
  content: "";
  border: 10px solid;
  border-color: inherit;
  width: 270%;
  height: 270%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  position: absolute;
  left: -100%;
  top: -100%;
  opacity: 1;
  -webkit-animation: 2s videomodule-anim linear infinite;
  animation: 2s videomodule-anim linear infinite;
  animation-delay: 0s;
}
.round-animation::before {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
@-webkit-keyframes videomodule-anim {
  0% {
    -webkit-transform: scale(0.68);
    transform: scale(0.68);
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes videomodule-anim {
  0% {
    -webkit-transform: scale(0.68);
    transform: scale(0.68);
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}
.banner-style-two .thumb .social {
  position: absolute;
  left: -40px;
  bottom: 0;
  max-width: 250px;
  height: auto !important;
}
.banner-style-two .thumb .social img {
  border-radius: inherit;
}
.banner-style-two h2 {
  font-size: 64px;
  font-weight: 600;
  margin-bottom: 35px;
  line-height: 1;
}
.banner-style-two p {
  font-size: 16px;
  line-height: 1.7;
}
.banner-style-two .button {
  margin-top: 30px;
}
.shape-text {
  position: relative;
  z-index: 1;
  display: inline-block;
}
.shape-text .shape {
  position: absolute;
  left: 0;
  bottom: -25px;
  z-index: -1;
}
.banner-style-two strong {
  position: relative;
  z-index: 1;
  display: inline-block;
  font-weight: 600;
}
.banner-style-two strong::after {
  position: absolute;
  left: 0;
  bottom: 0px;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../Images/shape/14.png);
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
@media (min-width: 1200px) {
  .banner-style-two p {
    padding-right: 10%;
  }
}
.banner-style-two .shape-top-right {
  width: 35%;
  top: 20%;
}
.banner-style-three-area {
  background-position: bottom center !important;
  position: relative;
}
.banner-style-three .content {
  padding-top: 180px;
}
.banner-style-three .thumb {
  margin-top: 60px;
  position: relative;
  z-index: 1;
}
.banner-style-three-area .animate-shape img:first-child {
  position: absolute;
  left: 50px;
  top: 160px;
  height: 100px;
  animation: moving 25s ease-out infinite;
}
.banner-style-three-area .animate-shape img:nth-child(2) {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.banner-style-three .banner-slide {
  overflow: hidden;
}
.banner-style-three h4 {
  display: inline-block;
  background: #ffffff;
  color: var(--color-primary);
  padding: 5px 20px;
  text-transform: uppercase;
  line-height: 1;
  border-radius: 30px;
  font-weight: 500;
  font-size: 18px;
  padding-top: 8px;
  margin-bottom: 25px;
}
.banner-style-three h2 {
  font-size: 80px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 30px;
}
.banner-style-three p {
  padding: 0 15%;
  margin: 0;
  font-size: 20px;
  line-height: 1.8;
}
.banner-style-four-area .row div {
  height: auto;
}
.banner-style-four-area .shape {
  position: absolute;
  left: 0;
  bottom: -6px;
  z-index: -1;
}
.banner-style-four-area .shape-text svg {
  stroke-width: 18px;
  stroke: var(--color-optional);
  height: 90px;
  width: 100%;
}
.banner-style-four .thumb {
  position: relative;
  z-index: 1;
  margin-bottom: 170px;
}
.banner-style-four .thumb .thumb-sub {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.banner-style-four .thumb .thumb-sub img:first-child {
  position: absolute;
  left: -50px;
  top: 200px;
  box-shadow: 0 25px 70px rgb(0 0 0 / 7%);
  background: #ffffff;
  padding: 15px;
  height: 250px;
  border-radius: 15px;
  z-index: 1;
}
.banner-style-four .thumb .thumb-sub img:nth-child(2) {
  position: absolute;
  right: 0;
  bottom: -120px;
}
.banner-style-four .thumb .thumb-sub img:nth-child(3) {
  position: absolute;
  left: 80px;
  bottom: -149px;
}
.banner-style-four .thumb::after {
  position: absolute;
  left: 50%;
  top: 72%;
  content: "";
  height: 400px;
  width: 400px;
  transform: translate(-50%, -50%);
  background: var(--bg-gradient);
  z-index: -1;
  border-radius: 50%;
}
.banner-style-four .thumb::before {
  position: absolute;
  left: 50%;
  top: 72%;
  content: "";
  height: 600px;
  width: 600px;
  transform: translate(-50%, -50%);
  background: var(--color-primary);
  z-index: -1;
  border-radius: 50%;
  opacity: 0.04;
}
.banner-style-four {
  padding-top: 150px;
  padding-bottom: 150px;
}
.banner-style-four h4 {
  text-transform: uppercase;
  color: var(--color-primary);
  font-weight: 500;
  background: var(--bg-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  position: relative;
  z-index: 1;
}
.banner-style-four h2 {
  font-size: 80px;
  line-height: 70px;
  font-weight: 600;
  margin-bottom: 40px;
}
.banner-style-four p {
  font-size: 20px;
  line-height: 1.8;
  padding-right: 10%;
  margin: 0;
}
.banner-style-four strong {
  position: relative;
  z-index: 1;
  display: inline-block;
  font-weight: 600;
}
.banner-style-four strong::after {
  position: absolute;
  left: 0;
  bottom: -3px;
  content: "";
  height: 100%;
  width: 100%;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='90' viewBox='0 0 500 150' stroke-width='18' stroke='%23FF5621' fill='none'%3E%3Cpath d='M15.2,133.3L15.2,133.3c121.9-7.6,244-9.9,366.1-6.8c34.6,0.9,69.1,2.3,103.7,4' fill='%23a1cdca'%3E%3C/path%3E%3C/svg%3E");
  z-index: -1;
  background-repeat: no-repeat;
  background-position: left bottom;
}
.banner-style-five-thumb img {
  max-width: 45%;
  border-radius: 10px;
}
.banner-style-five .content {
  padding-top: 200px;
  padding-bottom: 150px;
}
.banner-style-five-thumb img:first-child {
  z-index: -1;
  position: relative;
}
.banner-style-five-thumb img:nth-child(2) {
  float: right;
  margin-top: 80px;
}
.banner-style-five-thumb img:nth-child(3) {
  margin-top: 50px;
}
.banner-style-five-thumb {
  position: relative;
  z-index: 1;
}
.banner-style-five-thumb .shape {
  position: absolute;
  right: 35%;
  top: -25px;
  z-index: -1;
  text-align: right;
  height: 120%;
}
.banner-style-five-thumb .shape img {
  border-radius: inherit;
  max-width: inherit;
  height: 100%;
}
.banner-style-five h2 {
  font-size: 75px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 25px;
}
.banner-style-five h2 strong {
  display: inline-block;
  font-weight: 600;
  position: relative;
  z-index: 1;
}
.banner-style-five h2 strong::after {
  position: absolute;
  left: 0;
  bottom: 0px;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../Images/shape/19.png);
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.banner-style-five p {
  font-size: 20px;
  padding-right: 10%;
}
@media only screen and (max-width: 767px) {
  .banner-style-three-area {
    overflow: hidden;
  }
  .banner-style-three .content {
    padding-top: 60px;
  }
  .banner-style-three h2 {
    font-size: 36px;
    line-height: 1.2;
  }
  .banner-style-three .thumb {
    max-width: 80%;
    margin: 50px auto auto;
  }
  .banner-style-four-area {
    background: var(--bg-gray);
  }
  .banner-style-four h2 {
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 15px;
  }
  .banner-style-four p {
    padding: 0;
    font-size: 16px;
    line-height: 26px;
  }
  .banner-style-four .thumb .thumb-sub img:first-child {
    display: none;
  }
  .banner-style-four .thumb::before {
    height: 100%;
    width: 100%;
    display: none;
  }
  .banner-style-four .thumb {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .banner-style-four .thumb::after {
    height: 300px;
    width: 300px;
  }
  .banner-style-four-area .info {
    text-align: center;
  }
  .banner-style-four .thumb .thumb-sub img:nth-child(3) {
    display: none;
  }
  .banner-style-four .thumb .thumb-sub img:nth-child(2) {
    display: none;
  }
  .banner-style-four {
    padding-bottom: 90px;
    padding-top: 130px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-style-three-area {
    overflow: hidden;
  }
  .banner-style-three .content {
    padding-top: 120px;
  }
  .banner-style-three .thumb {
    max-width: 80%;
    margin: 60px auto auto;
  }
  .banner-style-four .thumb .thumb-sub img:first-child {
    display: none;
  }
  .banner-style-four-area {
    background: var(--bg-gray);
  }
  .banner-style-four {
    text-align: center;
    padding-top: 200px;
    padding-bottom: 120px;
  }
  .banner-style-four .thumb {
    margin-top: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .heading,
  .title {
    font-size: 36px;
    margin-top: 0;
    line-height: 1.2;
  }
  .banner-style-four-area {
    overflow: hidden;
  }
  .banner-style-four .thumb::before {
    height: 500px;
    width: 500px;
  }
  .banner-style-four .thumb .thumb-sub img:first-child {
    top: 120px;
    height: 200px;
  }
  .banner-style-four {
    padding-top: 250px;
  }
  .banner-style-four .thumb .thumb-sub img:nth-child(3) {
    left: -30px;
    max-width: 120px;
  }
  .banner-style-four .thumb .thumb-sub img:nth-child(2) {
    bottom: -180px;
    max-width: 170px;
  }
  .banner-style-four .thumb > img {
    position: relative;
    top: -90px;
  }
  .banner-style-two .align-center {
    align-items: center;
  }
  .banner-style-two-area .info {
    padding-top: 120px;
  }
  .about-style-four .thumb img:nth-child(4) {
    display: none;
  }
}
.shape-left-center {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom left;
}
.shape-left-bottom {
  position: absolute;
  left: 0;
  top: 400px;
  height: 500px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}
.feature-style-one {
  margin-bottom: 30px;
}
.feature-style-one p {
  margin: 0;
}
.feature-style-one i {
  display: inline-block;
  font-size: 45px;
  margin-bottom: 30px;
  height: 90px;
  width: 90px;
  text-align: center;
  line-height: 90px;
  position: relative;
  z-index: 1;
  color: var(--color-primary);
  transition: all 0.35s ease-in-out;
}
.feature-style-one i::before {
  line-height: inherit;
}
.feature-style-one i::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: var(--color-primary);
  z-index: -1;
  border-radius: 48% 52% 41% 59% / 52% 49% 51% 48%;
  opacity: 0.1;
  transition: all 0.35s ease-in-out;
}
.feature-style-one:first-child i::after,
.feature-style-one:hover i::after {
  opacity: 1;
}
.feature-style-one:nth-child(2) i {
  color: var(--color-optional);
}
.feature-style-one:nth-child(2) i::after {
  background: var(--color-optional);
  border-radius: 36% 64% 57% 43% / 50% 50% 50% 50%;
}
.feature-style-one:nth-child(3) i {
  color: #26c6da;
}
.feature-style-one:nth-child(3) i::after {
  background: #26c6da;
  border-radius: 48% 52% 40% 60% / 45% 41% 59% 55%;
}
.feature-style-one:first-child i,
.feature-style-one:hover i {
  color: var(--white) !important;
}
.feature-style-one .item {
  padding: 60px 40px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  transition: all 0.35s ease-in-out;
  box-shadow: 0 25px 70px rgb(0 0 0 / 7%);
  background: var(--white);
}
.feature-style-one .item::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 140px;
  width: 100%;
  background: url(../Images/shape/16.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left top;
  transition: all 0.35s ease-in-out;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px 0 0 10px;
}
.feature-style-one:first-child .item::after,
.feature-style-one .item:hover::after {
  opacity: 1;
  visibility: visible;
}
.feature-style-one:first-child .item,
.feature-style-one .item:hover {
  background: var(--white);
  box-shadow: inherit;
}
.feature-style-one a:hover {
  color: var(--color-heading);
}
.feature-style-one .item .bg {
  transition: all 0.25s ease-in-out;
  background-size: cover !important;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 80%;
  z-index: -1;
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
}
.feature-style-one:first-child .item .bg,
.feature-style-one .item:hover .bg {
  width: 100%;
  opacity: 1;
  visibility: visible;
}
.feature-style-three {
  margin-bottom: 30px;
}
.feature-style-three .item {
  padding: 60px 37px;
  background: #ffffff;
  box-shadow: 0px 25px 70px 0px rgb(0 0 0 / 7%);
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.feature-style-three .item::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 80%;
  width: 100%;
  z-index: -1;
  background: var(--bg-gradient);
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s ease-in-out;
}
.feature-style-three:nth-child(2n) .item::after,
.feature-style-three .item:hover::after,
.feature-style-three .item.active::after {
  visibility: visible;
  opacity: 1;
  height: 100%;
}
.feature-style-three .item i,
.feature-style-three .item p,
.feature-style-three .item h4 {
  transition: all 0.35s ease-in-out;
}
.feature-style-three .item h4 {
  font-size: 22px;
}
.feature-style-three .item p {
  margin: 0;
}
.feature-style-three .item i {
  display: inline-block;
  font-size: 70px;
  margin-bottom: 30px;
  background: linear-gradient(90deg, #00b5ca 0%, #d900ff 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.feature-style-three .item:hover i,
.feature-style-three .item:hover p,
.feature-style-three .item:hover h4,
.feature-style-three .item:hover a,
.feature-style-three .item:hover span,
.feature-style-three .item.active i,
.feature-style-three .item.active p,
.feature-style-three .item.active h4,
.feature-style-three .item.active a,
.feature-style-three .item.active span,
.feature-style-three:nth-child(2) .item i,
.feature-style-three:nth-child(2) .item p,
.feature-style-three:nth-child(2) .item a,
.feature-style-three:nth-child(2) .item span,
.feature-style-three:nth-child(2) .item h4 {
  color: var(--white);
}
.feature-style-three .item:hover i,
.feature-style-three .item.active i,
.feature-style-three:nth-child(2) .item i {
  background: transparent;
  -webkit-background-clip: inherit;
  -moz-background-clip: inherit;
  background-clip: inherit;
  -webkit-text-fill-color: inherit;
}
.feature-style-three .item:hover p,
.feature-style-three .item.active p,
.feature-style-three:nth-child(2) .item p {
  opacity: 0.9;
}
.feature-style-three .bottom {
  border-top: 1px solid #e7e7e7;
  margin-top: 20px;
  padding-top: 25px;
  transition: all 0.35s ease-in-out;
}
.feature-style-three:hover .item .bottom,
.feature-style-three:nth-child(2) .item .bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.feature-style-three .bottom span {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  display: block;
  margin-bottom: 3px;
  transition: all 0.35s ease-in-out;
}
.bottom-dark-120 {
  position: relative;
  z-index: 1;
}
.bottom-dark-120::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 120px;
  width: 100%;
  background: var(--dark);
  z-index: -1;
}
.shape-arrow {
  position: absolute;
  left: 100%;
  top: -75px;
  margin-left: -60px;
  height: 300px;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .shape-arrow {
    display: none;
  }
}
.shape-left-top-less-opacity {
  position: absolute;
  left: -50px;
  top: 0;
  height: 400px;
  width: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
  background-position: top left;
  opacity: 0.5;
}
.about-style-one .thumb img {
  background: var(--white);
  padding: 30px;
  box-shadow: 0 0 50px #e7ecf3;
  border-radius: 10px;
}
.about-style-one .thumb img:first-child {
  float: right;
  background: var(--bg-gradient);
  box-shadow: inherit;
}
.about-style-one .thumb img:nth-child(2) {
  position: absolute;
  left: -26%;
  top: 80px;
  max-width: 250px;
  z-index: 9;
}
.about-style-one .thumb {
  position: relative;
}
.about-style-one .thumb img:nth-child(3) {
  float: right;
  max-width: 50%;
  position: relative;
  z-index: 9;
  top: -80px;
  margin-bottom: -80px;
  margin-right: 50px;
}
.shape-right-center {
  position: absolute;
  right: 0;
  top: -120px;
  height: 700px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right top;
  z-index: -1;
  opacity: 0.5;
}
.author-info {
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: relative;
}
.author-info img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 2px solid #f9f9f9;
  padding: 3px;
  margin-right: 15px;
}
.author-info h4 {
  font-weight: 600;
  margin-bottom: 5px;
}
.author-info span {
  color: violet;
  color: var(--color-primary);
}
.about-style-one blockquote {
  padding: 0;
  margin: 0;
  font-size: 20px;
  line-height: 1.5;
  color: var(--color-heading);
  border: none;
  margin-top: 25px;
  font-style: italic;
}
.about-style-one .btn {
  margin-top: 30px;
}
.about-style-one .video-play-button {
  margin-top: 40px;
}
.about-style-two .thumb {
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
}
.about-style-two .thumb .sub-item img:nth-child(2) {
  position: absolute;
  right: -70px;
  top: 40px;
  max-width: 300px;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
}
.about-style-two .thumb .sub-item img:first-child {
  position: absolute;
  left: -50px;
  bottom: -50px;
  padding: 15px;
  max-width: 250px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
}
.about-style-two .thumb img {
  border-radius: 10px;
}
.item-increase h2 {
  font-weight: 600;
  font-size: 50px;
  display: flex;
  align-items: center;
  line-height: 1;
  margin-bottom: 10px;
}
.item-increase h2 i {
  font-size: 25px;
  font-weight: 400;
  margin-left: 10px;
  color: var(--color-paragraph);
}
.item-increase h4 {
  font-weight: 600;
}
.about-style-three-area {
  background-position: right bottom;
  background-repeat: no-repeat;
}
.about-style-three .thumb {
  position: relative;
  z-index: 1;
  bottom: -240px;
  margin-top: -240px;
  padding-right: 50px;
}
.about-style-three .thumb > img {
  max-width: 80%;
}
.about-style-three .thumb::after {
  position: absolute;
  left: -20%;
  top: -5%;
  content: "";
  height: 120%;
  width: 120%;
  background: url(../Images/shape/46.png);
  background-repeat: no-repeat;
  z-index: -1;
  background-size: contain;
  background-position: center;
}
.about-style-three .thumb .sub-thumb {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.about-style-three .thumb .sub-thumb img {
  position: absolute;
  right: -15px;
  top: 0;
  border-radius: 10px;
  height: 260px;
  z-index: -1;
}
.grow-business {
  display: inline-block;
  text-align: center;
  height: 220px;
  width: 220px;
  background: var(--bg-gradient);
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 120px;
}
.grow-business h2 {
  margin: 0;
  font-weight: 700;
  font-size: 42px;
  line-height: 1;
}
.grow-business::after {
  position: absolute;
  left: -10%;
  top: -10%;
  content: "";
  height: 120%;
  width: 120%;
  background: var(--bg-gradient);
  border-radius: 50%;
  z-index: -1;
  opacity: 0.3;
}
.about-style-three span {
  display: inline-block;
  position: relative;
  z-index: 1;
  overflow: hidden;
  top: 11px;
}
.about-style-three span .border-shape {
  position: absolute;
  left: 0;
  bottom: 6px;
  content: "";
  height: 10px;
  width: 100%;
  background: var(--bg-gradient);
  z-index: -1;
}
.about-style-three h2 {
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 55px;
  line-height: 1.1;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-top: -10px;
}
.list-style-three {
  display: flex;
  margin-top: 30px;
  padding-top: 30px;
  transition: all 0.35s ease-in-out;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.list-style-three span {
  width: 180px;
  min-width: 90px;
  font-size: 60px;
  position: relative;
  top: -5px;
  overflow: inherit;
  font-weight: 900;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.4);
  line-height: 1;
}
.list-style-three h4 {
  font-weight: 600;
}
.list-style-three p {
  margin: 0;
  opacity: 0.8;
}
.list-style-three:first-child {
  border: none;
  margin-top: 0;
  padding-top: 0;
}
.about-style-three p.large {
  font-size: 18px;
  line-height: 1.8;
  opacity: 0.9;
  margin: 0;
}
.about-style-four .thumb {
  position: relative;
  margin-top: 55px;
}
.about-style-four .thumb img:first-child {
  max-width: 320px;
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.88);
  background: var(--white);
  padding: 30px;
  border-radius: 4px;
}
.about-style-four .thumb img:nth-child(2) {
  background: #f9f9f9;
  padding: 40px;
  border-radius: 10px;
  border: 1px solid #e1dfe8;
  position: absolute;
  right: 0;
  top: 95px;
  max-width: 60%;
  z-index: -1;
}
.about-style-four .thumb .icon {
  display: inline-block;
  height: 120px;
  width: 120px;
  background: var(--white);
  text-align: center;
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
  position: absolute;
  margin-left: -50px;
  z-index: -1;
  top: -57px;
  border-radius: 4px;
}
.about-style-four .thumb i {
  display: inline-block;
  background: #cdfbe1;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: #6de898;
  border-radius: 15px;
  border: 2px solid #6de898;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  height: 70px;
  width: 70px;
  line-height: 70px;
}
.about-style-four .thumb img:nth-child(3) {
  background: var(--white);
  padding: 20px;
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
  position: absolute;
  left: -70px;
  top: 15%;
  height: 100px;
  border-radius: 4px;
}
.about-style-four .thumb .shape img {
  box-shadow: none;
  padding: 0;
  height: auto;
  width: 150px;
  background: transparent;
  border-radius: inherit;
}
.about-style-four .thumb .shape {
  position: absolute;
  top: -50px;
  left: -30px;
  z-index: -1;
}
.about-style-four .thumb img:nth-child(4) {
  background: var(--white);
  padding: 30px;
  height: 120px;
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
  border-radius: 4px;
  position: absolute;
  bottom: 0;
  right: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-style-three .thumb .sub-thumb img:nth-child(2) {
    right: 0;
    top: 130px;
  }
  .about-style-three .thumb {
    margin: 0;
    bottom: 0;
    margin-bottom: 10px;
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .about-style-three .thumb .sub-thumb img:nth-child(2) {
    display: none;
  }
  .about-style-three .thumb {
    bottom: 0;
    margin: 0;
    display: none;
  }
  .about-style-three .thumb .sub-thumb {
    display: none;
  }
  .about-style-three .thumb > img {
    padding: 0;
  }
  .about-style-three h2 {
    font-size: 42px;
  }
  .list-style-three {
    display: block;
    text-align: center;
  }
  .list-style-three span {
    margin: 0;
    margin-bottom: 30px;
  }
  .about-style-three {
    text-align: center;
  }
}
.fun-fact {
  text-align: center;
}
.fun-fact .counter {
  position: relative;
}
.fun-fact .counter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 45px;
  font-weight: 600;
  color: var(--color-heading);
  line-height: 1;
  margin-bottom: 5px;
  font-family: var(--font-default);
}
.text-light .fun-fact .counter {
  color: var(--white);
}
.fun-fact .medium {
  font-weight: 600;
}
.text-light .fun-fact .medium {
  color: #e2e1e1;
}
.fun-factor-style-one .fun-fact {
  background: rgba(0, 0, 0, 0.06);
  padding: 35px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}
.brand-area {
  position: relative;
}
.curve-top {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  top: -1px;
}
.curve-top svg {
  width: calc(100% + 1.3px);
  height: 30px;
  display: block;
  width: calc(100% + 1.3px);
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.curve-top.angle svg {
  height: 100px;
  display: block;
  width: calc(100% + 100px);
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.brand-items img {
  width: auto;
  margin: auto;
  padding: 0 25px;
}
.brand3col {
  text-align: center;
}
.brand-items h2 {
  font-weight: 600;
}
.brand-items h4 {
  margin: 0;
  font-weight: 500;
}
.brand-items strong {
  font-size: 30px;
  position: relative;
  z-index: 1;
  font-weight: 700;
}
.brand-heading h4 strong {
  font-size: 30px;
  text-decoration: underline;
  color: var(--color-primary);
  font-weight: 600;
}
.brand3col .swiper-wrapper {
  align-items: center;
}
.shape-left-bottom-center {
  position: absolute;
  left: 0;
  bottom: 0;
}
.color-heading {
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 70px;
  line-height: 1;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
  margin-top: -15px;
}
.clients-map {
  display: inline-block;
  margin: auto;
  position: relative;
}
.clients-map li {
  position: absolute;
  z-index: 1;
}
.clients-map li:first-child {
  top: 90px;
  left: 50px;
}
.clients-map li:nth-child(2) {
  top: 200px;
  left: 200px;
}
.clients-map li:nth-child(3) {
  top: 80px;
  right: 200px;
}
.clients-map li:nth-child(4) {
  top: 160px;
  right: 320px;
}
.clients-map li:nth-child(5) {
  bottom: 140px;
  left: 50%;
  margin-left: 15px;
}
.clients-map li::after {
  position: absolute;
  left: 50%;
  bottom: 0;
  content: "";
  height: 20px;
  width: 20px;
  transform: translateX(-50%);
  background: var(--bg-gradient);
  z-index: -1;
  border-radius: 50%;
}
.clients-map li::before {
  position: absolute;
  left: 50%;
  bottom: -5px;
  content: "";
  height: 30px;
  width: 30px;
  background: var(--color-primary);
  z-index: -1;
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  margin-left: -15px;
}
.clients-map li:nth-child(2):before {
  position: absolute;
  left: 50%;
  bottom: -5px;
  content: "";
  height: 30px;
  width: 30px;
  background: var(--color-primary);
  z-index: -1;
  border-radius: 50%;
  animation: pulsate 1.2s ease-out;
  animation-iteration-count: infinite;
  margin-left: -15px;
}
.clients-map li:nth-child(3):before {
  position: absolute;
  left: 50%;
  bottom: -5px;
  content: "";
  height: 30px;
  width: 30px;
  background: var(--color-primary);
  z-index: -1;
  border-radius: 50%;
  animation: pulsate 1.3s ease-out;
  animation-iteration-count: infinite;
  margin-left: -15px;
}
.clients-map li:nth-child(4):before {
  position: absolute;
  left: 50%;
  bottom: -5px;
  content: "";
  height: 30px;
  width: 30px;
  background: var(--color-primary);
  z-index: -1;
  border-radius: 50%;
  animation: pulsate 1.4s ease-out;
  animation-iteration-count: infinite;
  margin-left: -15px;
}
.clients-map li:nth-child(5):before {
  position: absolute;
  left: 50%;
  bottom: -5px;
  content: "";
  height: 30px;
  width: 30px;
  background: var(--color-primary);
  z-index: -1;
  border-radius: 50%;
  animation: pulsate 1.5s ease-out;
  animation-iteration-count: infinite;
  margin-left: -15px;
}
.clients-map li a {
  position: relative;
  left: 0;
  top: -40px;
  display: inline-block;
  background: var(--white);
  padding: 2px 10px;
  border-radius: 5px;
  box-shadow: 0 5px 3px rgb(3 27 78 / 8%);
}
.clients-map li a {
  position: relative;
  left: 0;
  top: -40px;
  display: inline-block;
  background: var(--dark);
  padding: 1px 15px;
  border-radius: 5px;
  box-shadow: 0 5px 3px rgb(3 27 78 / 8%);
  color: var(--white);
}
.clients-map li a::after {
  position: absolute;
  left: 50%;
  bottom: -8px;
  content: "";
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid var(--dark);
  transform: translateX(-50%);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clients-map li:nth-child(2) {
    left: 160px;
  }
  .clients-map li:nth-child(3) {
    right: 100px;
    top: 45px;
  }
  .clients-map li:nth-child(4) {
    top: 100px;
  }
  .clients-map li:nth-child(5) {
    left: auto;
    right: 70px;
    bottom: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .color-heading {
    font-size: 40px;
    margin-top: -5px;
  }
  .clients-worldwide {
    background: var(--bg-gray);
    padding-bottom: 50px;
  }
  .clients-map li:nth-child(2) {
    top: auto;
    bottom: 10%;
    left: 50%;
  }
  .clients-map li:nth-child(5) {
    display: none;
  }
  .clients-map li:nth-child(4) {
    display: none;
  }
  .clients-map li:nth-child(3) {
    top: 0;
    right: 30px;
  }
  .clients-map li:first-child {
    top: 50px;
  }
}
.services-tab-navs .nav-tabs {
  display: block;
  background: var(--white);
  padding: 55px 50px;
  position: relative;
  padding-bottom: 75px;
  top: -50px;
  border: none;
  margin-bottom: -50px;
  border-radius: 8px;
  box-shadow: 0 5px 3px rgba(3, 27, 78, 0.08);
}
.services-tab-navs .nav-tabs .nav-link {
  margin: 0;
  padding: 15px 0;
  width: 100%;
  text-align: left;
  border: none;
  border-bottom: 1px solid #f1f1f1;
  color: var(--color-heading);
  font-size: 20px;
  font-weight: 600;
  transition: all 0.35s ease-in-out;
  position: relative;
  display: flex;
  align-items: center;
  position: relative;
}
.services-tab-navs .nav-tabs .nav-link.active {
  border-color: #dddddd;
}
.services-tab-navs .nav-tabs .nav-link::after {
  position: absolute;
  right: -50px;
  bottom: -1px;
  content: "";
  height: 1px;
  width: 0;
  background: #dddddd;
  transition: all 0.45s ease-in-out;
}
.services-tab-navs .nav-tabs .nav-link.active::after {
  width: 60%;
}
.services-tab-navs .nav-tabs .nav-link::before {
  position: absolute;
  content: "";
  top: 20px;
  right: -90px;
  height: 40px;
  width: 40px;
  border-left: solid var(--white);
  border-top: solid transparent;
  border-bottom: solid transparent;
  border-width: 20px;
  -webkit-filter: drop-shadow(3px 0 0 rgba(3, 27, 78, 0.03));
  filter: drop-shadow(3px 0 0 rgba(3, 27, 78, 0.03));
  transition: all 0.4s ease;
  visibility: hidden;
  opacity: 0;
}
.services-tab-navs .nav-tabs .nav-link.active::before {
  opacity: 1;
  visibility: visible;
}
.services-tab-navs .nav-tabs .nav-link:first-child {
  margin-top: 0;
}
.services-tab-navs .nav-tabs .nav-link.active i {
  color: var(--color-primary);
}
.services-tab-navs .nav-tabs .nav-link i {
  display: block;
  font-size: 55px;
  margin-right: 20px;
  position: relative;
  top: 5px;
}
.services-tab-navs .nav-tabs .nav-link b {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-paragraph);
  letter-spacing: 0.6px;
  display: block;
  margin-bottom: 0;
}
.services-style-one .info .title {
  font-weight: 700;
}
ul.list-standard {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-standard li {
  position: relative;
  font-size: 17px;
  padding-left: 30px;
  line-height: 2;
  font-weight: 500;
}
.list-standard li::after {
  position: absolute;
  left: 0;
  top: 7px;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-size: 11px;
  font-weight: 500;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 18px;
  color: var(--color-heading);
  border-radius: 5px;
  border: 1px solid #c1b4b4;
}
.btn-regular.large {
  border-bottom: 2px solid;
  line-height: 1;
  display: inline-block;
  font-size: 20px;
  color: var(--color-primary);
  overflow: inherit;
  padding-bottom: 3px;
  font-weight: 500;
}
.btn-regular i {
  font-size: 15px;
  position: relative;
  margin-left: 2px;
  font-weight: 600;
  transform: rotate(-55deg);
}
.services-style-one .info {
  position: relative;
  z-index: 1;
}
.services-style-one .info h2 {
  font-weight: 600;
  margin-bottom: 25px;
}
.services-style-one .thumb {
  position: relative;
  z-index: 1;
}
.services-style-one .thumb::after {
  position: absolute;
  right: -100px;
  top: -70px;
  content: "";
  height: 300px;
  width: 300px;
  background: linear-gradient(20deg, transparent, rgba(255, 255, 255, 1));
  z-index: -1;
  border-radius: 50%;
}
.services-style-one .info {
  position: relative;
  z-index: 1;
}
.shape-animation-up-down {
  position: absolute;
  right: 50px;
  top: -70px;
  max-width: 150px;
}
.services-style-two {
  margin-bottom: 30px;
}
.services-style-two .item > i {
  display: inline-block;
  font-size: 45px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  height: 90px;
  width: 90px;
  line-height: 90px;
  background: -webkit-linear-gradient(top left, #1baaa0, #11ebdb);
  border-radius: 48% 52% 41% 59% / 52% 49% 51% 48%;
  text-align: center;
  color: var(--white);
  box-shadow: 0px 10px 30px 0px rgb(44 130 237 / 40%);
  font-weight: 600;
}
.services-style-two .item > i::before {
  line-height: inherit;
}
.services-style-two .item {
  padding: 60px 30px;
  box-shadow: 0px 0px 200px 0px rgb(0 0 0 / 5%);
  border-radius: 10px;
  background: var(--white);
  position: relative;
  z-index: 1;
  transition: all 0.35s ease-in-out;
  padding-bottom: 40px;
  overflow: hidden;
}
.services-style-two .item .shape {
  position: absolute;
  right: -50px;
  transition: all 0.35s ease-in-out;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.services-style-two:first-child .item .shape,
.services-style-two .item:hover .shape,
.services-style-two .item.active .shape {
  right: 0;
  opacity: 1;
  visibility: visible;
}
.services-style-two .item:hover,
.services-style-two .item.active,
.services-style-two:first-child .item {
  padding-bottom: 75px;
}
.services-style-two:nth-child(2) .item > i {
  background: linear-gradient(to bottom right, #4154f1, #6c7cff);
}
.services-style-two:nth-child(3) .item > i {
  background: linear-gradient(to bottom right, #f84e77, #ffa3b9);
}
.services-style-two:nth-child(4) .item > i {
  background: linear-gradient(to bottom right, #63bc6f, #a0f8ac);
}
.services-style-two .btn-icon {
  bottom: 10px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  left: 10px;
}
.services-style-two .btn-icon::after {
  margin-top: -2px;
  display: none;
}
.services-style-two .item .btn-icon {
  padding-left: 35px;
  text-transform: uppercase;
  border-bottom: 1px solid;
  color: var(--color-paragraph);
}
.services-style-two .item:hover .btn-icon,
.services-style-two .item.active .btn-icon,
.services-style-two:first-child .item .btn-icon {
  opacity: 1;
  visibility: visible;
  bottom: 45px;
  left: 30px;
}
.services-style-two .item .btn-icon:hover,
.services-style-two .item .btn-icon.active {
  color: var(--color-primary);
}
.services-style-two .item .btn-icon i {
  transform: rotate(-45deg);
  margin-left: 4px;
  font-weight: 500;
}
.services-style-four-area {
  background-size: 40%, contain;
  background-repeat: no-repeat;
  background-position: center left;
}
.services-style-four {
  margin-bottom: 30px;
}
.services-style-four .item > i {
  display: inline-block;
  font-size: 70px;
  margin-bottom: 30px;
  background: linear-gradient(90deg, #00b5ca 0%, #d900ff 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.services-style-four p {
  margin: 0;
}
.services-style-four .item {
  padding: 60px 50px;
  box-shadow: 0px 25px 70px 0px rgb(0 0 0 / 7%);
  overflow: hidden;
  position: relative;
  z-index: 1;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  transition: all 0.35s ease-in-out;
}
.services-style-four .item::after {
  background: var(--bg-gradient);
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 90%;
  z-index: -1;
  transition: all 0.25s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.services-style-four .item:hover::after,
.services-style-four .item.active::after,
.services-style-four:first-child .item::after {
  opacity: 1;
  visibility: visible;
  width: 100%;
}
.services-style-four .item .thumb {
  max-width: 80px;
  min-width: 80px;
  margin-right: 30px;
}
.services-style-four ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 19px;
}
.services-style-four li {
  position: relative;
  z-index: 1;
  padding-left: 30px;
  font-weight: 500;
  margin-top: 9px;
}
.services-style-four li::after {
  position: absolute;
  left: 0;
  top: 2px;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  height: 20px;
  width: 20px;
  line-height: 20px;
  border: 1px solid #ccbebe;
  text-align: center;
  border-radius: 5px;
  color: var(--color-heading);
  font-size: 11px;
}
.services-style-four p,
.services-style-four li,
.services-style-four a {
  transition: all 0.35s ease-in-out;
}
.services-style-four .item:hover p,
.services-style-four .item:hover li,
.services-style-four .item:hover a,
.services-style-four .item.active p,
.services-style-four .item.active li,
.services-style-four .item.active a,
.services-style-four:first-child .item p,
.services-style-four:first-child li,
.services-style-four:first-child a {
  color: var(--white);
}
.services-style-four .item:hover li::after,
.services-style-four .item.active li::after,
.services-style-four:first-child .item li::after {
  background: var(--white);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .services-style-four-area .align-center {
    align-items: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .services-style-four .item {
    display: block;
  }
  .services-style-four .item .thumb {
    margin: 0;
    margin-bottom: 30px;
  }
  .services-style-four-area .align-center {
    align-items: center;
  }
}
@media only screen and (max-width: 767px) {
  .services-style-four .btn {
    margin-bottom: 20px;
  }
  .services-style-four .item {
    display: block;
  }
  .services-style-four .item .thumb {
    margin: 0;
    margin-bottom: 30px;
  }
}
.services-details-area .thumb img {
  margin-bottom: 40px;
  border-radius: 10px;
}
.services-details-area h1,
.services-details-area h2,
.services-details-area h3,
.services-details-area h4,
.services-details-area h5,
.services-details-area h6 {
  font-weight: 600;
}
.feature-list-item {
  margin: 0;
  padding: 0;
  list-style: none;
}
.feature-list-item li {
  position: relative;
  z-index: 1;
  padding-left: 32px;
  margin-top: 8px;
  font-weight: 500;
}
.feature-list-item li::after {
  position: absolute;
  left: 0;
  top: 3px;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  color: var(--color-primary);
  font-weight: 500;
  height: 20px;
  width: 20px;
  line-height: 20px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
}
.quick-contact-widget {
  padding: 60px 37px;
  text-align: center;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  border-radius: 7px;
}
.quick-contact-widget h2 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 25px;
}
.quick-contact-widget h4 a {
  font-weight: 400;
  border-bottom: 2px solid;
}
.quick-contact-widget i {
  display: inline-block;
  height: 60px;
  width: 60px;
  line-height: 60px;
  font-weight: 100;
  background: #ffffff;
  color: var(--color-primary);
  font-size: 22px;
  border-radius: 50%;
  margin-bottom: 40px;
  position: relative;
}
.quick-contact-widget i::after {
  position: absolute;
  left: -10px;
  top: -10px;
  content: "";
  height: 80px;
  width: 80px;
  background: #ffffff;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.8;
}
.quick-contact-widget::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: var(--dark);
  z-index: -1;
  opacity: 0.6;
  border-radius: 7px;
}
.quick-contact-widget .btn-sm {
  font-size: 16px;
}
.services-sidebar .single-widget {
  margin-top: 50px;
}
.services-sidebar .single-widget .widget-title {
  display: block;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: -5px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
  display: inline-block;
  padding-bottom: 15px;
}
.services-sidebar .single-widget .widget-title::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 2px;
  width: 50px;
  border-bottom: 2px solid var(--color-primary);
}
.services-sidebar .single-widget h4.widget-title {
  font-size: 22px;
}
.services-sidebar .single-widget:first-child {
  margin-top: 0;
}
.widget-brochure ul {
  padding-left: 0;
  list-style: none;
}
.widget-brochure ul li a {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 15px;
  background: #ffffff;
  padding: 20px 25px;
  border-radius: 5px;
  border: 2px solid #cddff7;
  color: var(--color-heading);
}
.widget-brochure ul li a:hover {
  color: var(--color-primary);
}
.widget-brochure ul li:first-child a {
  margin-top: 0;
}
.widget-brochure ul li:first-child a,
.widget-brochure ul li a:hover {
  background: var(--color-primary);
  border-color: transparent;
  color: var(--white);
}
.widget-brochure ul li i {
  font-size: 35px;
  font-weight: 100;
  margin-right: 16px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.widget-brochure ul li:hover i,
.widget-brochure ul li:first-child i {
  color: var(--white);
}
.services-list-widget ul {
  list-style: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.services-list-widget ul li {
  display: block;
}
.services-list-widget ul li a {
  display: block;
  padding: 18px 25px;
  border-bottom: 1px solid #c8e0ff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  color: var(--color-heading);
  background: #f1f4f8;
}
.services-list-widget ul li a::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 0;
  background: var(--color-primary);
  background-size: 220% 150%;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  z-index: -1;
}
.services-list-widget ul li a:hover {
  color: #ffffff;
}
.services-list-widget ul li a:hover::before {
  width: 100%;
}
.services-list-widget ul li.current-item a {
  color: #ffffff;
}
.services-list-widget ul li.current-item a::after {
  position: absolute;
  right: 20px;
  top: 50%;
  content: "\f061";
  border-radius: 50%;
  font-family: "Font Awesome 5 Pro";
  font-weight: 500;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 17px;
}
.services-list-widget ul li.current-item a::before {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .services-more .row {
    margin-top: -20px;
  }
}
.services-more .item {
  padding: 35px;
  background: var(--bg-gray);
  margin-top: 15px;
  border-radius: 10px;
}
@media only screen and (max-width: 767px) {
  .services-more .item {
    margin-top: 30px;
    text-align: center;
  }
}
.services-more .item i::before {
  line-height: inherit;
}
.services-more .item i {
  display: inline-block;
  font-size: 40px;
  margin-bottom: 30px;
  background: var(--color-primary);
  color: var(--white);
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  border-radius: 50%;
}
.services-more .item a {
  color: var(--color-heading);
}
.services-more .item a:hover {
  color: var(--color-primary);
}
.services-more .item p {
  margin: 0;
}
.boosting-items {
  padding: 120px 80px;
  border: 2px solid;
  border-radius: 10px;
  position: relative;
  background: var(--white);
}
.boosting-items::after {
  position: absolute;
  left: -30px;
  bottom: -30px;
  content: "";
  height: 100%;
  width: 100%;
  background: var(--bg-gradient);
  z-index: -1;
  border-radius: 10px;
  opacity: 0.1;
}
ul.list-icon {
  margin: 0;
  padding: 0;
}
ul.list-icon li {
  display: flex;
  margin-top: 30px;
  border-top: 1px solid #e7e7e7;
  padding-top: 30px;
}
ul.list-icon li:first-child {
  border: none;
  padding-top: 0;
}
ul.list-icon li .icon {
  margin-right: 30px;
}
ul.list-icon li i {
  display: inline-block;
  font-size: 60px;
  color: var(--color-primary);
  text-align: center;
  background: linear-gradient(90deg, #00b5ca 0%, #d900ff 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0 5px;
  line-height: 1;
}
ul.list-icon li p {
  margin: 0;
}
ul.list-icon li h4 {
  font-weight: 600;
}
ul.list-icon li i::before {
  line-height: inherit;
}
.half-bg-gray-bottom {
  position: relative;
  z-index: 1;
}
.half-bg-gray-bottom::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 384px;
  width: 100%;
  background: var(--bg-gray);
  z-index: -1;
}
.project-style-one {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 25px 70px rgb(0 0 0 / 7%);
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
}
.swiper-slide .project-style-one {
  box-shadow: 0 5px 3px rgb(3 27 78 / 8%);
}
.project-style-one .content {
  padding: 20px 30px;
  position: relative;
}
.project-style-one ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.project-style-one li {
  display: inline-block;
  margin-right: 13px;
  position: relative;
  z-index: 1;
}
.project-style-one li::after {
  position: absolute;
  right: -11px;
  top: 11px;
  content: "";
  height: 5px;
  width: 5px;
  background: #b3a7a7;
  z-index: -1;
  border-radius: 50%;
}
.project-style-one li:last-child {
  margin: 0;
}
.project-style-one li:last-child::after {
  display: none;
}
.project-style-one h4 {
  margin-bottom: 5px;
}
.project-more-info h2 {
  font-weight: 600;
}
.project-more-info .btn {
  float: right;
}
@media (min-width: 992px) {
  .project-more-info h2,
  .project-more-info p {
    margin: 0;
  }
}
.project-style-one .button {
  position: absolute;
  right: 0;
  top: -24px;
  display: inline-block;
  height: 48px;
  width: 48px;
  line-height: 45px;
  background: var(--bg-gradient);
  border-radius: 50%;
  text-align: center;
  border: 3px solid var(--white);
  color: var(--white);
  opacity: 0;
  visibility: hidden;
}
.project-style-one:hover .button,
.single-item:first-child .project-style-one .button {
  right: 30px;
  opacity: 1;
  visibility: visible;
}
.project-style-one .thumb {
  overflow: hidden;
}
.project-style-one .thumb img {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.project-style-one:hover .thumb img {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.shape-right-top {
  height: 30%;
  position: absolute;
  right: 0;
  top: -100px;
  width: 550px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right top;
}
.project-carousel {
  padding-bottom: 50px;
}
.swiper-control {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  min-width: 220px;
}
.project-carousel .swiper-button-prev::before {
  position: absolute;
  top: 50%;
  right: inherit;
  bottom: inherit;
  left: 12px;
  content: "";
  height: 2px;
  width: 50px;
  z-index: -1;
  background-color: var(--color-primary);
  margin-top: -1px;
}
.project-carousel .swiper-button-prev::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f104";
  font-weight: 100;
  font-size: 30px;
  position: absolute;
  left: 8px;
  color: var(--color-primary);
}
.project-carousel .swiper-button-next::before {
  position: absolute;
  top: 50%;
  right: 12px;
  bottom: inherit;
  left: inherit;
  content: "";
  height: 2px;
  width: 50px;
  z-index: -1;
  background-color: var(--color-primary);
  margin-top: -1px;
}
.project-carousel .swiper-button-next::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f105";
  font-weight: 100;
  font-size: 30px;
  position: absolute;
  right: 8px;
  color: var(--color-primary);
}
.project-carousel .swiper-control .swiper-pagination {
  bottom: -14px;
  font-weight: 500;
  font-family: var(--font-default);
  font-size: 24px;
  letter-spacing: -1.6px;
  color: var(--color-heading);
}
.project-carousel .swiper-control .swiper-pagination span {
  font-family: var(--font-default);
}
.project-carousel .swiper-button-next,
.project-carousel .swiper-button-prev {
  width: 60px;
}
.project-details-area .top-info {
  position: relative;
  margin-top: 50px;
}
.project-details-area .top-info .left-info {
  padding-right: 35px;
}
.project-details-area .top-info p:last-child {
  margin-bottom: 0;
}
.project-details-area .main-content h2,
.project-details-area .main-content h3,
.project-details-area .main-content h4,
.project-details-area .main-content h5 {
  font-weight: 600;
  margin-bottom: 25px;
}
.project-details-area .main-content .row img {
  margin-top: 30px;
}
.project-details-area .top-info h2 {
  font-weight: 600;
  margin-bottom: 20px;
}
.project-thumb {
  position: relative;
  z-index: 1;
}
.project-thumb img {
  border-radius: 10px;
}
.project-info {
  background: var(--white);
  position: absolute;
  right: 50px;
  bottom: -50px;
  max-width: 450px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 20px 50px rgb(0 0 0 / 10%);
}
.project-info .content {
  padding: 50px;
  padding-top: 40px;
  padding-bottom: 45px;
}
.project-info .title {
  background: var(--dark);
  color: var(--white);
  padding: 15px 50px;
  font-weight: 500;
  margin: 0;
  font-size: 27px;
}
.project-info .project-basic-info {
  padding-left: 0;
  list-style: none;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 15px;
}
.project-info .project-basic-info li {
  font-weight: 600;
  color: var(--color-heading);
}
.project-info .project-basic-info li span {
  font-weight: 400;
  position: relative;
  display: block;
  font-family: var(--font-default);
  color: var(--color-paragraph);
}
.project-info ul.social {
  margin-top: 25px;
  border-top: 1px solid #e7e7e7;
  padding-top: 25px;
}
.project-info ul.social li {
  display: inline-block;
  margin: 0;
  margin-right: 5px;
  margin-top: 5px;
}
.project-info ul.social li a {
  display: inline-block;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 100;
  height: 38px;
  width: 38px;
  text-align: center;
  line-height: 40px;
  background: var(--color-primary);
  color: var(--white);
}
.project-info ul.social li:last-child {
  margin-right: 0;
}
.project-info ul.social h4 {
  margin: 0;
  display: inline-block;
  text-transform: capitalize;
  font-weight: 600;
  margin-right: 10px;
}
.project-details-area .check-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}
.project-details-area .check-list h4 {
  font-weight: 600;
  position: relative;
  z-index: 1;
  padding-left: 45px;
  font-size: 27px;
}
.project-details-area .check-list h4::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  height: 30px;
  width: 30px;
  text-align: center;
  background: #f9f9f9;
  line-height: 30px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid;
}
.project-details-area .check-list p {
  margin: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .project-details-area .top-info {
    margin-top: 100px;
  }
}
.choose-us-area {
  background-size: 350px, contain;
  background-repeat: no-repeat;
  background-position: right bottom;
}
.animate-illustration {
  position: relative;
  z-index: 1;
}
.animate-illustration .sub-item img {
  position: absolute;
}
.animate-illustration .sub-item img:first-child {
  right: 50px;
  top: -50px;
  height: 250px;
}
.animate-illustration .sub-item img:nth-child(2) {
  right: 0;
  bottom: 50px;
}
.animate-illustration .sub-item img:nth-child(3) {
  top: 0;
  left: 40px;
}
.animate-illustration .sub-item img:nth-child(4) {
  left: 50%;
  margin-left: -76px;
  bottom: -20px;
}
.animate-illustration .sub-item img:nth-child(5) {
  left: 0;
  bottom: 40px;
}
.animate-illustration .sub-item img:nth-child(6) {
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: -60px;
  margin-left: -50px;
}
.choose-us-style-one ul {
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  margin-top: 35px;
  grid-column-gap: 30px;
}
.list-item h5 {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 20px;
}
.list-item .item {
  margin-top: 17px;
  position: relative;
  z-index: 1;
  padding-left: 35px;
}
.list-item .item:first-child {
  margin-top: 0;
}
.list-item .item::after {
  position: absolute;
  left: 0;
  top: 2px;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  height: 22px;
  width: 22px;
  background: var(--bg-gradient);
  text-align: center;
  line-height: 22px;
  color: var(--white);
  border-radius: 50%;
  font-size: 11px;
  font-weight: 500;
}
.progressbar .circle,
.progressbar-hard .circle {
  display: inline-block;
  position: relative;
}
.progressbar .circle strong,
.progressbar-hard .circle strong {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  color: var(--color-heading);
  font-size: 25px;
  margin-top: -2px;
  margin-left: 2px;
}
.choose-us-style-one .progressbar {
  text-align: center;
  display: inline-block;
  background: var(--white);
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 25px 70px rgb(0 0 0 / 7%);
}
.choose-us-style-one .progressbar h4 {
  margin: 0;
  font-weight: 600;
  margin-top: 3px;
}
.choose-us-style-three .thumb {
  text-align: center;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}
.experience-box {
  position: absolute;
  right: -50px;
  bottom: -30px;
  height: 300px;
  width: 300px;
  text-align: center;
  background: var(--bg-gradient);
  border-radius: 50%;
  box-shadow: 0px 4px 60px 30px rgba(0, 0, 0, 0.05);
}
.choose-us-style-three .thumb .thumb-inner img {
  width: 70%;
}
.choose-us-style-three .thumb .thumb-inner {
  margin: auto;
  border-radius: 0 0 480px 485px;
  overflow: hidden;
  text-align: center;
}
.choose-us-style-three .thumb::after {
  position: absolute;
  left: 50%;
  bottom: 0;
  content: "";
  height: 600px;
  width: 600px;
  background: #e9edff;
  z-index: -1;
  transform: translateX(-50%);
  border-radius: 50%;
}
.shape-combo {
  position: absolute;
  left: 0;
  top: -50px;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.shape-combo img {
  position: absolute;
  height: 200px;
  right: 50px;
  top: 200px;
}
.choose-us-style-three .thumb .shape img {
  position: absolute;
  height: 150px;
  left: -25px;
  top: 140px;
  z-index: 1;
  transform: rotate(20deg);
}
.circle-progress-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
  margin-top: 40px;
}
.circle-progress-item h4 {
  margin: 0;
  font-weight: 600;
  margin-top: 10px;
}
.circle-progress-item {
  border-right: 1px solid #e7e7e7e7;
}
.circle-progress-item:last-child {
  border: none;
}
.experience-box strong {
  display: block;
  font-size: 100px;
  font-weight: 600;
  line-height: 1;
}
.experience-box h2 {
  font-size: 22px;
  font-weight: 400;
  margin: 0;
  color: var(--white);
}
.experience-box .item-inner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .choose-us-style-three-area .align-center {
    align-items: center;
  }
  .choose-us-style-three .thumb::after {
    height: 500px;
    width: 500px;
  }
  .choose-us-style-three .thumb .thumb-inner {
    border-radius: 0 0 422px 412px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .choose-us-style-three-area {
    padding-top: 50px;
  }
  .choose-us-style-three .thumb {
    margin-bottom: 70px;
  }
  .choose-us-style-three .thumb .thumb-inner img {
    max-width: 400px;
  }
  .experience-box {
    right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .choose-us-style-three .thumb {
    display: none;
  }
  .shape-combo {
    display: none;
  }
  .circle-progress-items {
    display: block;
  }
  .circle-progress-item {
    border: none;
    margin-top: 40px;
  }
}
.process-area {
  position: relative;
  z-index: 1;
}
.process-area::after {
  position: absolute;
  left: 5%;
  top: 120px;
  content: "";
  height: 100px;
  width: 100px;
  background: var(--white);
  clip-path: polygon(
    45% 1.33975%,
    46.5798% 0.60307%,
    48.26352% 0.15192%,
    50% 0%,
    51.73648% 0.15192%,
    53.4202% 0.60307%,
    55% 1.33975%,
    89.64102% 21.33975%,
    91.06889% 22.33956%,
    92.30146% 23.57212%,
    93.30127% 25%,
    94.03794% 26.5798%,
    94.48909% 28.26352%,
    94.64102% 30%,
    94.64102% 70%,
    94.48909% 71.73648%,
    94.03794% 73.4202%,
    93.30127% 75%,
    92.30146% 76.42788%,
    91.06889% 77.66044%,
    89.64102% 78.66025%,
    55% 98.66025%,
    53.4202% 99.39693%,
    51.73648% 99.84808%,
    50% 100%,
    48.26352% 99.84808%,
    46.5798% 99.39693%,
    45% 98.66025%,
    10.35898% 78.66025%,
    8.93111% 77.66044%,
    7.69854% 76.42788%,
    6.69873% 75%,
    5.96206% 73.4202%,
    5.51091% 71.73648%,
    5.35898% 70%,
    5.35898% 30%,
    5.51091% 28.26352%,
    5.96206% 26.5798%,
    6.69873% 25%,
    7.69854% 23.57212%,
    8.93111% 22.33956%,
    10.35898% 21.33975%
  );
  opacity: 0.1;
}
.process-area::before {
  position: absolute;
  left: 12%;
  top: 220px;
  content: "";
  height: 50px;
  width: 50px;
  background: var(--white);
  opacity: 0.1;
  border-radius: 50%;
}
@media (max-width: 1250px) {
  .process-area::before {
    left: 14%;
  }
}
.center-sahpe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}
.process-area .center-sahpe {
  top: 90px;
}
.process-style-one .icon {
  display: inline-block;
  height: 150px;
  width: 150px;
  text-align: center;
  line-height: 150px;
  background: var(--white);
  border-radius: 50%;
  color: var(--color-primary);
  font-size: 60px;
  margin-bottom: 30px;
}
.process-style-one .icon img {
  height: 60px;
}
.process-style-one i::before {
  line-height: inherit;
}
.process-style-one h4 {
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
}
.process-style-one .icon {
  display: inline-block;
  position: relative;
  z-index: 1;
}
.process-style-one .icon span {
  position: absolute;
  bottom: 15px;
  right: -5px;
  background: var(--color-primary);
  height: 45px;
  width: 45px;
  line-height: 43px;
  border-radius: 50%;
  border: 2px solid var(--white);
  font-weight: 800;
  font-size: 16px;
}
.process-style-one:nth-child(2n) .icon span {
  bottom: auto;
  top: 15px;
  right: -15px;
}
.process-style-two-area {
  position: relative;
  z-index: 1;
  background-size: contain;
  background-repeat: no-repeat;
}
.process-style-two-thumb {
  margin-top: -45px;
  position: relative;
  z-index: 1;
}
.process-style-two-thumb img {
  width: 100%;
}
.process-style-two-list {
  position: relative;
  z-index: 1;
  text-align: center;
  height: 500px;
  width: 500px;
  margin-top: 35px;
}
.process-style-two-list .border-shape {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  border: 2px dashed #dddddd;
  border-radius: 50%;
  animation: spinnerReverse 40s infinite linear;
}
.process-style-two-list li {
  height: 150px;
  width: 150px;
  text-align: center;
  background: var(--white);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -80px;
  z-index: 1;
}
.process-style-two-list h4 {
  margin: 0;
  font-weight: 600;
  text-transform: capitalize;
}
.process-style-two-list::after {
  position: absolute;
  left: 10%;
  top: 10%;
  content: "";
  height: 80%;
  width: 80%;
  border: 2px dashed #eeeeee;
  border-radius: 50%;
  animation: spinner 30s infinite linear;
}
.process-style-two-list::before {
  position: absolute;
  left: 20%;
  top: 20%;
  content: "";
  height: 60%;
  width: 60%;
  background: var(--color-primary);
  border-radius: 50%;
  z-index: -1;
}
.process-style-two-list li:nth-child(2) {
  right: -40px;
  bottom: 35px;
  left: auto;
  transform: inherit;
  top: auto;
}
.process-style-two-list li:nth-child(3) {
  transform: inherit;
  top: auto;
  bottom: 35px;
  right: auto;
  left: -40px;
}
.process-style-two-list li:nth-child(4) {
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  right: auto;
  background: var(--white);
  height: 180px;
  width: 180px;
}
.process-style-two-list li .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.process-style-two-list li .content span {
  font-weight: 900;
  font-size: 40px;
  color: var(--color-primary);
  text-shadow: -3px 3px 0px #eceefe;
}
.process-style-two-list i {
  font-size: 50px;
  color: var(--color-heading);
  margin-bottom: 12px;
  display: inline-block;
}
.proces-style-two-list h4 {
  font-weight: 600;
  position: relative;
  z-index: 1;
}
.proces-style-two-list h4::after {
  position: absolute;
  left: -55px;
  top: 15px;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-size: 17px;
  font-weight: 100;
  height: 35px;
  width: 35px;
  line-height: 37px;
  background: var(--color-primary);
  text-align: center;
  border-radius: 50%;
  margin-top: -20px;
  color: var(--white);
}
.process-list-box {
  border-left: 1px solid #dddddd;
  padding-left: 38px;
  margin-left: 17px;
}
.proces-style-two-list {
  margin-bottom: 20px;
}
.proces-style-two-list:last-child {
  margin-bottom: 0;
}
.proces-style-two-list p {
  margin: 0;
}
.process-style-two .heading {
  margin-bottom: 50px;
}
.team-style-one-area {
  background-size: 100%, contain;
  background-repeat: no-repeat;
  background-position: bottom;
}
.team-style-one-item .thumb {
  position: relative;
  z-index: 1;
}
.team-style-one-item .thumb img {
  border-radius: 50%;
  padding: 5px;
}
.team-style-one-item h4 {
  margin-bottom: 3px;
}
.team-grid {
  margin-top: 60px;
}
.team-grid:last-child {
  margin-top: 0;
}
.team-style-one-item {
  margin-bottom: 30px;
  text-align: center;
  padding: 40px 50px;
  background: var(--white);
  border-radius: 15px;
  box-shadow: 0 20px 50px rgb(0 0 0 / 10%);
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.team-grid .team-style-one-item:first-child {
  margin-top: 0;
}
.team-style-one-item .thumb::after {
  position: absolute;
  right: -4px;
  bottom: -5px;
  content: "";
  height: 105%;
  width: 105%;
  background: var(--bg-gradient);
  z-index: -1;
  border-radius: 48% 52% 41% 59% / 52% 49% 51% 48%;
  display: none;
}
.team-style-one-item .thumb::before {
  position: absolute;
  left: -5px;
  top: -5px;
  content: "";
  height: 101%;
  width: 102%;
  border: 1px solid #cccccc;
  border-radius: 50%;
  z-index: -1;
  transition: all 0.35s ease-in-out;
}
.team-style-one-item:hover .thumb::before {
  left: 0;
  top: 3px;
}
.team-style-one-item .info {
  margin-top: 20px;
}
.team-style-one-item .thumb .social li {
  display: inline-block;
  margin: 0 1px;
}
.team-style-one-item .thumb .social li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background: var(--color-primary);
  line-height: 45px;
  border-radius: 50%;
  color: var(--white);
  position: relative;
  bottom: -30px;
  opacity: 0;
  visibility: hidden;
}
i::before {
  line-height: inherit;
}
.team-style-one-item .social {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.team-style-one-item .thumb .social li:last-child a {
  transition: all 0.55s ease-in-out;
}
.team-style-one-item:hover .thumb .social li a {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}
.team-style-one-item .social li.facebook a {
  background: #3b5998;
}
.team-style-one-item .social li.twitter a {
  background: #000000;
}
@media only screen and (max-width: 767px) {
  .team-style-one:first-child .team-style-one-item {
    margin-top: 0;
  }
}
.team-single-area .team-content-top {
  bottom: -50px;
  margin-top: -50px;
  position: relative;
  z-index: 1;
}
.team-single-area .team-content-top .right-info {
  padding-left: 35px;
  padding-right: 50px;
  margin-bottom: 50px;
}
.team-single-area .team-content-top .right-info h2 {
  font-weight: 600;
}
.team-single-area .team-content-top .right-info span {
  display: block;
  text-transform: uppercase;
  color: var(--color-primary);
  font-weight: 600;
  margin-bottom: 25px;
}
.team-single-area .right-info ul {
  margin-top: 25px;
  border-top: 1px solid #e7e7e7;
  padding-top: 17px;
}
.team-single-area .right-info ul li {
  margin-top: 10px;
  color: var(--color-heading);
}
.team-single-area .right-info ul li strong {
  font-weight: 600;
}
.team-single-area .right-info ul li a {
  font-weight: 400;
}
.team-single-area .right-info ul li a:hover {
  color: var(--color-primary);
}
.team-single-area .right-info .social {
  display: flex;
  margin-top: 25px;
  font-weight: 600;
  align-items: center;
}
.team-single-area .right-info .social h4 {
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 25px;
}
.team-single-area .right-info .social ul {
  margin: 0;
  padding: 0;
  border: none;
}
.team-single-area .right-info .social .share-link {
  position: relative;
  z-index: 1;
  margin-left: 15px;
  padding-right: 20px;
}
.team-single-area .right-info .social .share-link > i {
  display: inline-block;
  height: 45px;
  background: var(--white);
  box-shadow: 0 0 10px #cccccc;
  line-height: 45px;
  width: 45px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  color: var(--color-primary);
}
.team-single-area .right-info .social ul {
  display: flex;
  list-style-type: none;
  grid-gap: 10px;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out 0s;
}
.team-single-area .right-info .social .share-link:hover ul {
  left: 58px;
  opacity: 1;
  pointer-events: auto;
}
.team-single-area .right-info .social ul li {
  display: inline-block;
  margin: 0;
}
.team-single-area .right-info .social ul li a {
  display: inline-block;
  height: 45px;
  width: 45px;
  line-height: 47px;
  background: #f1f1f1;
  text-align: center;
  border-radius: 50%;
}
.team-single-area .bottom-info h2 {
  font-weight: 600;
  margin-bottom: 25px;
}
.team-single-area .bottom-info p:last-child {
  margin-bottom: 0;
}
.team-single-area .bottom-info .skill-items {
  padding-left: 35px;
}
.skill-items .progress-box {
  margin-bottom: 40px;
}
.team-single-area .team-content-top img {
  border-radius: 30px;
}
.skill-items .progress-box:last-child {
  margin-bottom: 0;
}
.skill-items .progress-box h5 {
  font-weight: 500;
  margin-bottom: 0;
  text-align: left;
  position: relative;
  z-index: 1;
  font-size: 18px;
}
.skill-items .progress-box h5 span {
  position: absolute;
  font-size: 50px;
  line-height: 1;
  top: -21px;
  left: 0;
  z-index: -1;
  opacity: 0.05;
  font-weight: 600;
}
.skill-items .skill-items {
  margin-top: 40px;
}
.skill-items .progress-box .progress {
  background: transparent;
  border-bottom: none;
  box-shadow: inherit;
  border-radius: inherit;
  overflow: inherit;
}
.skill-items .progress-box .progress .progress-bar {
  height: 9px;
  border-radius: 30px;
  background: var(--color-primary);
  top: 12px;
  position: relative;
  overflow: inherit;
}
.skill-items .progress-box .progress .progress-bar span {
  position: absolute;
  right: 0;
  top: -36px;
  display: block;
  font-size: 18px;
  color: var(--color-heading);
  font-weight: 500;
}
.seo-expert-item {
  position: absolute;
}
.seo-expert-item img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 1px solid #e7e7e7;
  padding: 3px;
  background: var(--white);
}
.seo-expert-item .rating {
  display: flex;
  align-items: center;
  justify-content: center;
}
.seo-expert-item .rating h5 {
  margin: 0;
  line-height: 1;
  font-weight: 600;
  margin-right: 15px;
}
.seo-expert-item h4 {
  font-weight: 600;
  margin-bottom: 3px;
}
.seo-expert-item span {
  display: block;
}
.seo-expert-item .rating i {
  font-size: 14px;
  color: #ffca40;
}
.seo-expert-items {
  position: relative;
  height: 400px;
  width: 400px;
  margin: auto;
  background: #e5e0ec;
  border-radius: 50%;
  left: 55px;
  margin-top: 120px;
  margin-bottom: 150px;
}
.seo-expert-items::after {
  position: absolute;
  left: -15%;
  top: -15%;
  content: "";
  height: 130%;
  width: 130%;
  background: #efecf5;
  z-index: -1;
  border-radius: 50%;
}
.seo-expert-items::before {
  position: absolute;
  left: -30%;
  top: -30%;
  content: "";
  height: 160%;
  width: 160%;
  background: #f9f9f9;
  z-index: -1;
  border-radius: 50%;
}
.seo-expert-item:first-child {
  left: 50%;
  top: -85px;
  margin-left: -50px;
}
.seo-expert-item:nth-child(2) {
  left: -70px;
  top: 90px;
}
.seo-expert-item:nth-child(3) {
  bottom: -150px;
  left: 50%;
  margin-left: -50px;
}
.seo-expert-item .content {
  background: var(--white);
  padding: 15px 30px;
  padding-bottom: 10px;
  display: inline-block;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  border: 1px solid #faf0f0;
}
.seo-expert-item .content::after {
  position: absolute;
  content: "";
  top: -18px;
  left: 19px;
  border-left: solid var(--white);
  border-top: solid transparent;
  border-bottom: solid transparent;
  border-width: 13px;
  -webkit-filter: drop-shadow(3px 0 0 rgba(3, 27, 78, 0.03));
  filter: drop-shadow(3px 0 0 rgba(3, 27, 78, 0.03));
  transition: all 0.4s ease;
  transform: rotate(-90deg);
}
.expert-fun-fact .fun-fact {
  display: inline-block;
  margin-right: 50px;
  margin-top: 40px;
}
.expert-fun-fact .fun-fact:last-child {
  margin-right: 0;
}
.expert-fun-fact .fun-fact .counter {
  justify-content: inherit;
}
.shape-left-bottom-less-opacity {
  position: absolute;
  left: -100px;
  top: 80px;
  z-index: -1;
}
.shape-left-bottom-less-opacity img {
  height: 200px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .seo-expert-area .align-center {
    align-items: center;
  }
  .shape-left-bottom-less-opacity {
    top: 50px;
    left: 0;
  }
  .shape-left-bottom-less-opacity img {
    height: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .shape-left-bottom-less-opacity {
    display: none;
  }
}
.seo-score-area .seo-score {
  padding: 80px 30px;
  border-radius: 10px;
  background: var(--white);
  box-shadow: 0 5px 30px 0 rgb(214 215 216 / 57%);
  position: relative;
  z-index: 1;
}
.rocket-animation {
  position: absolute;
  right: 50px;
  top: -50px;
  animation: UpDown 2s linear infinite;
}
.seo-score form {
  border: 1px solid #e7e7e7;
  border-radius: 30px;
  position: relative;
  z-index: 1;
  padding: 0 20px;
  background: var(--white);
}
.seo-score form .col-lg-6 {
  padding: 0;
}
.seo-score form input,
.seo-score form input:focus {
  border: none;
  border-radius: 30px;
  box-shadow: inherit;
  outline: inherit;
  min-height: 60px;
  padding: 15px 30px;
}
.seo-score form button {
  top: 5px;
  position: absolute;
  right: 5px;
}
.seo-score form .input-group.stylish-input-group {
  border-radius: 30px;
  border: none;
}
.seo-score form button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 11px 50px;
  border-radius: 30px;
  color: #ffffff;
  font-weight: 700;
  border: none;
  background: transparent;
  z-index: 1;
}
.seo-score button::after {
  position: absolute;
  top: 0;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: linear-gradient(
    to right,
    var(--color-primary),
    var(--color-secondary),
    var(--color-primary)
  );
  background-size: 220% 150%;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  border-radius: 30px;
}
.seo-score button:hover::after {
  background-position: -70% 0;
}
.rocket-animation img {
  height: 300px;
}
.seo-score form input[type="url"] {
  border-right: 1px solid #e7e7e7;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}
.seo-score h2 {
  font-weight: 600;
  font-size: 50px;
  line-height: 1.1;
}
.seo-score h2 strong {
  display: inline-block;
  font-weight: 600;
  color: var(--color-primary);
}
.seo-score h2 .text-border-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 10px;
  width: 100%;
  background: #d1d8f9;
  z-index: -1;
}
.half-bg-gray {
  position: relative;
  z-index: 1;
}
.half-bg-gray::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 120px;
  width: 100%;
  background: var(--bg-gray);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shape-left-bottom-less-opacity {
    left: 50px;
  }
  .seo-expert-area {
    text-align: center;
  }
  .shape-left-bottom-less-opacity img {
    height: 100px;
  }
  .seo-expert-items {
    left: 0;
    margin-top: 180px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rocket-animation img {
    height: 200px;
  }
  .rocket-animation {
    right: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .rocket-animation {
    display: none;
  }
  .seo-score form {
    border: none;
  }
  .seo-score form input {
    border: 1px solid #e7e7e7;
    border-radius: 30px !important;
    margin-bottom: 15px;
  }
  .seo-score form button {
    position: inherit;
    width: 100%;
    min-height: 60px;
  }
  .seo-score form .col-lg-6 {
    padding: 0 15px;
  }
  .seo-expert-items {
    height: 100%;
    width: 100%;
    background: transparent;
    position: inherit;
    left: 0;
    margin: 0;
    margin-top: 50px;
  }
  .seo-expert-items::before,
  .seo-expert-items::after {
    display: none;
  }
  .seo-expert-item {
    position: inherit;
    left: 0 !important;
    right: 0 !important;
    top: auto !important;
    bottom: auto;
    transform: inherit;
    margin: 0 !important;
    text-align: center;
    margin-top: 50px !important;
  }
  .seo-expert-area {
    text-align: center;
  }
  .expert-fun-fact .fun-fact {
    margin-left: 20px;
    margin-right: 20px;
  }
  .expert-fun-fact .fun-fact .counter {
    justify-content: center;
  }
  .seo-expert-item .content::after {
    left: 50%;
    margin-left: -10px;
  }
  .seo-expert-item img {
    margin-bottom: 20px;
    width: 300px;
    height: auto;
  }
  .seo-expert-item .content {
    width: 100%;
    padding: 25px 30px;
    padding-top: 30px;
  }
}
.faq-area {
  position: relative;
  z-index: 1;
}
.faq-sahpe img {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.faq-sahpe img:first-child {
  left: auto;
  right: 20px;
  bottom: -50px;
  animation: fadeRightLeft 5s ease-out infinite;
}
.faq-sahpe img:nth-child(2) {
  max-width: 200px;
}
.faq-style-one button.accordion-button {
  color: var(--color-heading);
  border: none;
  box-shadow: inherit;
  font-size: 18px;
  font-weight: 600;
  padding: 20px 0;
  background: transparent;
  border-radius: inherit !important;
  padding-right: 60px !important;
  position: relative;
  border-bottom: 2px solid !important;
}
.faq-style-one button.accordion-button::after {
  background: transparent;
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  font-weight: 100;
  font-size: 25px;
  position: absolute;
  top: 20px;
  right: 25px;
}
.faq-style-one button.accordion-button:not(.collapsed)::after {
  -webkit-transform: inherit;
  transform: inherit;
  content: "\f106";
}
.faq-style-one button.accordion-button:focus {
  background: transparent;
  color: var(--color-heading);
  border: none;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  padding-left: 0;
  font-weight: 600;
}
.faq-style-one .accordion-item {
  background: transparent;
  border: none;
}
.faq-style-one .accordion-item h2 {
  margin: 0;
}
.faq-style-one .accordion-body {
  padding: 0;
  position: relative;
  z-index: 1;
  padding-top: 35px;
  padding-bottom: 10px;
}
.faq-style-one .accordion-body::before {
  position: absolute;
  left: 30px;
  right: 30px;
  content: "";
  height: 2px;
  display: block !important;
  top: 0;
}
.faq-style-one .accordion-body p {
  margin: 0;
}
.faq-style-one button.accordion-button strong {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 34px;
  text-align: center;
  border-radius: 50%;
  margin-right: 12px;
  border: 1px solid #e7e7e7;
}
.quick-contact-frame {
  z-index: 1;
  padding: 50px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.quick-contact-frame img {
  position: absolute;
  z-index: -1;
  left: -10px;
  top: -15px;
}
.quick-contact-frame h2 {
  font-weight: 600;
}
.quick-contact-frame h4 {
  font-weight: 600;
  margin-bottom: 30px;
  display: inline-block;
}
.quick-contact-frame p {
  margin: 0;
  padding: 0 15%;
  opacity: 0.9;
}
.faq-style-one {
  margin-top: -8px;
}
.testimonials-area,
.testimonial-style-two-area {
  position: relative;
}
.rating-provider {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.rating-provider .thumb {
  padding-left: 20px;
  margin-right: 20px;
}
.rating-provider img {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  box-shadow: 0px 2px 12px 0px #e7e7e7;
  float: left;
  margin-left: -22px;
  border: 3px solid #ffffff;
}
.rating-provider .ratings i {
  display: inline-block;
  font-size: 13px;
  letter-spacing: 2px;
  color: #ffca40;
}
.rating-icon {
  display: flex;
  align-items: center;
}
.rating-icon h4 {
  margin: 0;
  font-weight: 600;
  margin-left: 5px;
}
.rating-provider span {
  display: block;
  margin-top: 3px;
}
.testimonial-style-one-carousel {
  padding: 15px;
  margin: -15px;
  padding-top: 20px;
}
.testimonial-style-one-item {
  padding: 50px 37px;
  border-radius: 12px;
  border: 1px solid #e7e7e7;
  position: relative;
  z-index: 1;
  background: var(--white);
}
.testimonial-style-one-item .provider {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.testimonial-style-one-item .provider .info {
  position: relative;
  top: 4px;
}
.testimonial-style-one-item .provider img {
  height: 60px;
  width: 60px;
  min-width: 60px;
  margin-right: 20px;
  border-radius: 50%;
}
.testimonial-style-one-item .provider h4 {
  font-weight: 600;
  margin-bottom: 5px;
}
.testimonial-style-one-item .quote-icon img {
  height: 40px;
  position: absolute;
  z-index: -1;
  top: -20px;
  right: 37px;
}
.testimonial-style-one-item p {
  font-size: 18px;
  line-height: 1.7;
  margin-bottom: 0;
  font-weight: 400;
}
.testimonial-style-one-item .provider span strong {
  color: var(--color-primary);
}
.testimonial-style-one-item .shape {
  position: absolute;
  height: 50%;
  width: 50%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.15;
}
.testimonial-style-one {
  position: relative;
  z-index: 1;
}
.shape-right-bottom {
  position: absolute;
  bottom: -40px;
  right: -30px;
  z-index: -1;
  opacity: 0.7;
}
.testimonials-area .shape-right-bottom,
.testimonial-style-two-area .shape-right-bottom {
  animation: UpDown 4s linear infinite;
}
.shape-right-bottom img {
  height: 100px;
}
.shape-animation {
  position: absolute;
  left: 50px;
  top: 80px;
  z-index: -1;
  animation: zoomUpDown 3s linear infinite;
}
.shape-animation img {
  max-width: 60px;
}
.testimonial-style-two {
  position: relative;
  z-index: 1;
}
.testimonial-style-two-item {
  padding: 50px 30px;
  background: var(--white);
  border-radius: 5px;
}
.testimonial-style-two-item p {
  font-size: 18px;
  line-height: 1.7;
}
.testimonial-style-two-item .provider {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.testimonial-style-two-item .provider img {
  height: 70px;
  width: 70px;
  min-width: 70px;
  margin-right: 21px;
  border-radius: 50%;
}
.testimonial-style-two-item .provider h4 {
  margin-bottom: 3px;
  font-weight: 600;
}
.testimonial-style-two .rating-provider {
  margin-left: 0;
}
.testimonial-style-two .rating-provider .ratings {
  margin: 0;
}
.testimonial-style-two-item .provider .thumb {
  position: relative;
  z-index: 1;
}
.testimonial-style-two-item .provider .thumb::after {
  position: absolute;
  right: 12px;
  top: 10px;
  content: "";
  height: 30px;
  width: 30px;
  background-image: url(../Images/shape/quote.png);
  background-color: var(--color-primary);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
}
.testimonial-style-two-item .provider strong {
  font-weight: 600;
  color: var(--color-primary);
}
.testimonial-style-two-area .shape-animation {
  z-index: inherit;
}
.pricing-style-one-item {
  padding: 50px;
  position: relative;
  z-index: 1;
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
  border: 2px solid transparent;
}
.pricing-style-one i::before {
  line-height: inherit;
}
.pricing-style-one.active .pricing-style-one-item {
  border: 2px solid var(--color-primary);
  margin-top: 21px;
  padding-top: 70px;
}
.pricing-style-one-item > img {
  position: absolute;
  right: 50px;
  height: 100px;
}
.pricing-style-one-item > i {
  display: inline-block;
  position: absolute;
  z-index: 1;
  right: 50px;
  height: 80px;
  width: 80px;
  text-align: center;
  line-height: 90px;
  font-size: 40px;
  color: var(--white);
  font-weight: 100;
}
.pricing-style-one-item > i.fa-crown::before {
  position: relative;
  top: -6px;
}
.pricing-style-one-item > i::after {
  position: absolute;
  left: -18px;
  top: 0;
  content: "";
  height: 100px;
  width: 100px;
  background: url(../Images/shape/29.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}
.pricing-style-one-item .badge {
  position: absolute;
  top: -23px;
  background: var(--color-primary);
  color: var(--white);
  display: inline-block;
  margin: 0;
  padding: 17px 20px;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 5px;
  border-top-right-radius: 0;
}
.pricing-style-one-item .badge::after {
  position: absolute;
  right: -15px;
  top: 0;
  content: "";
  border-right: 15px solid transparent;
  border-bottom: 23px solid var(--color-primary);
  z-index: -1;
  opacity: 0.8;
}
.pricing-style-one-item h4 {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 24px;
}
.pricing-style-one-item span {
  display: block;
  margin-bottom: 10px;
}
.pricing-style-one-item h2 {
  font-size: 60px;
  font-weight: 700;
}
.pricing-style-one-item h2 sub {
  font-size: 16px;
  left: -10px;
  font-weight: 400;
}
.pricing-style-one-item .price {
  border-bottom: 1px dashed #dddddd;
  margin-bottom: 25px;
}
.pricing-style-one-item h2 sup {
  top: -18px;
  font-size: 30px;
}
.pricing-style-one-item li {
  line-height: 1.5;
  color: var(--color-heading);
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
  display: flex;
}
.pricing-style-one-item li i {
  font-weight: 400;
  margin-right: 8px;
  color: #36cc72;
  font-size: 15px;
  width: 15px;
  position: relative;
  top: 5px;
}
.pricing-style-one-item li i.fa-times {
  color: red;
}
.curve-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
}
.pricing-area {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.pricing-style-three-box {
  border: 1px solid #e7e7e7;
  overflow: hidden;
  border-radius: 8px;
}
.pricing-style-three {
  padding: 40px 50px;
  position: relative;
  overflow: hidden;
  background: #fbfbfb;
}
.pricing-style-three .row > div {
  position: relative;
  z-index: 1;
  padding-left: 30px;
}
.pricing-style-three .row > div:first-child {
  padding-left: 0;
  display: flex;
  justify-content: center;
}
.pricing-style-three .row > div::after {
  position: absolute;
  right: 0;
  top: -200px;
  content: "";
  height: 500px;
  width: 1px;
  background: #e7e7e7;
}
.pricing-style-three .row > div:last-child::after {
  display: none;
}
.pricing-style-three:nth-child(2) .row > div::after {
  background: rgba(255, 255, 255, 0.2);
}
.pricing-style-three:nth-child(2n) .btn::after {
  background: var(--white);
}
.pricing-style-three:nth-child(2n) .btn {
  color: var(--color-heading);
}
.pricing-style-three .button {
  display: flex;
  justify-content: center;
}
.pricing-style-three span {
  display: block;
  margin-bottom: 10px;
}
.pricing-style-three h2 {
  font-size: 50px;
  font-weight: 700;
}
.pricing-style-three h2 sub {
  font-size: 15px;
  left: -10px;
  font-weight: 400;
}
.pricing-style-three h2 sup {
  top: -18px;
  font-size: 30px;
}
.pricing-style-three li {
  line-height: 35px;
  color: var(--color-heading);
  font-size: 16px;
  font-weight: 400;
}
.pricing-style-three li i {
  font-weight: 400;
  margin-right: 5px;
  color: #36cc72;
  font-size: 15px;
}
.pricing-style-three li i.fa-times {
  color: red;
}
.pricing-style-three img {
  height: 100px;
}
.pricing-style-three .title h2 {
  font-size: 24px;
  margin: 0;
}
.pricing-style-three .title span {
  display: inline-block;
  background: var(--bg-gradient);
  color: var(--white);
  font-size: 16px;
  padding: 0px 20px;
  margin-bottom: 15px;
  position: absolute;
  left: 0;
  transform: rotate(-90deg) translateY(25%);
  opacity: 1;
  zoom: 1.009;
  will-change: transform;
  top: 38px;
}
.pricing-style-three:nth-child(2n),
.pricing-style-three.active {
  background: var(--bg-gradient);
}
.pricing-style-three:nth-child(2n) h2,
.pricing-style-three.active h2 {
  color: var(--white);
}
.pricing-style-three:nth-child(2n) li,
.pricing-style-three.active li {
  color: var(--white);
  opacity: 0.9;
}
.pricing-style-three:nth-child(2n) li i,
.pricing-style-three.active li i {
  color: var(--white);
}
.pricing-style-three.active .btn.btn-dark,
.pricing-style-three:nth-child(2n) .btn.btn-dark {
  color: var(--color-primary);
  background: var(--white);
  border: none;
}
.pricing-style-three.active .btn.btn-dark::after,
.pricing-style-three:nth-child(2n) .btn.btn-dark::after {
  background-color: var(--white);
}
.pricing-style-three.active .btn.btn-dark:hover::after,
.pricing-style-three:nth-child(2n) .btn.btn-dark:hover::after {
  width: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pricing-style-three img {
    max-width: 100px;
  }
  .pricing-style-three .align-center {
    align-items: center;
  }
  .pricing-style-three .title h2 {
    font-size: 24px;
  }
  .pricing-style-three .btn {
    padding: 13px 34px;
  }
  .pricing-style-three h2 sub {
    font-size: 14px;
    display: block;
  }
  .pricing-style-three h2 sup {
    font-size: 22px;
  }
  .pricing-style-three .price {
    text-align: center;
    padding-left: 15px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pricing-style-one-item .btn {
    padding: 16px 42px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pricing-style-three {
    text-align: center;
    margin-top: 30px;
    border: 1px solid #e7e7e7;
  }
  .pricing-style-three .row > div::after {
    display: none;
  }
  .pricing-style-three img {
    max-width: 250px;
    margin-bottom: 30px;
  }
  .pricing-style-three .title h2 {
    margin-bottom: 30px;
  }
  .pricing-style-three .details ul {
    display: inline-block;
    text-align: left;
    margin-bottom: 20px;
  }
  .pricing-style-three .button {
    text-align: center !important;
    margin-top: 15px;
  }
  .pricing-style-three:first-child {
    margin-top: 0;
  }
  .pricing-style-three-box {
    border: none;
  }
  .pricing-style-three .title span {
    zoom: normal;
  }
}
@media only screen and (max-width: 767px) {
  .pricing-style-three {
    text-align: center;
    margin-top: 30px;
    border: 1px solid #e7e7e7;
  }
  .pricing-style-three .row > div::after {
    display: none;
  }
  .pricing-style-three img {
    max-width: 250px;
    margin-bottom: 30px;
  }
  .pricing-style-three .title h2 {
    margin-bottom: 30px;
  }
  .pricing-style-three .details ul {
    display: inline-block;
    text-align: left;
    margin-bottom: 20px;
  }
  .pricing-style-three .button {
    text-align: center !important;
    margin-top: 15px;
  }
  .pricing-style-three:first-child {
    margin-top: 0;
  }
  .pricing-style-three-box {
    border: none;
  }
  .pricing-style-three .title span {
    transform: inherit;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    border-radius: 0 0 10px 10px;
  }
  .pricing-style-three .row > div {
    padding-left: 12px;
  }
}
.seo-heading {
  position: relative;
  z-index: 1;
}
.seo-heading::after {
  position: absolute;
  right: 0;
  top: -200%;
  content: "";
  height: 600%;
  width: 300%;
  background: var(--dark);
  z-index: -1;
}
.why-seo-style-one i {
  display: inline-block;
  font-size: 60px;
  margin-bottom: 30px;
}
.why-seo-style-one img {
  height: 80px;
  margin-bottom: 30px;
}
.why-seo-style-one h4 {
  font-weight: 600;
}
.why-seo-style-one p {
  margin: 0;
  opacity: 0.9;
}
.shape-right-bottom-last {
  position: absolute;
  right: -200px;
  top: 120px;
  height: 100%;
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
}
.shape-right-bottom-last img {
  height: 100%;
}
.seo-heading .sub-title {
  color: var(--color-primary);
}
.seo-heading h2 {
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-right: 35px;
  padding-bottom: 29px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .seo-heading::after {
    display: none;
  }
  .seo-heading .sub-title {
    color: var(--white);
    opacity: 0.8;
  }
  .seo-heading h2 {
    margin: 0;
  }
  .seo-carousel {
    padding-top: 35px;
  }
}
@media only screen and (max-width: 767px) {
  .why-seo-area {
    text-align: center;
  }
  .seo-heading::after {
    display: none;
  }
  .why-seo-box .sub-title {
    color: var(--white);
    opacity: 0.9;
  }
  .seo-carousel {
    padding-top: 30px;
  }
  .seo-heading h2 {
    margin-right: 0;
  }
}
.blog-area {
  background-repeat: no-repeat;
  background-position: right top;
}
.blog-area.blog-grid .thumb-less .item {
  box-shadow: none;
  border: 1px solid #e7e7e7;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center;
  transition: all 0.35s ease-in-out;
  border-radius: inherit;
  overflow: hidden;
  border-radius: 7px;
}
.blog-area.blog-grid .thumb-less .item:hover {
  background-position: right;
}
.blog-area.blog-grid .thumb-less .item .tags {
  margin-bottom: 20px;
}
.blog-area.blog-grid .thumb-less .btn-simple {
  margin-top: 20px;
}
.blog-area.blog-grid .thumb-less .btn-simple:hover {
  color: var(--white);
}
.blog-area.blog-grid .thumb-less .button {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  margin-top: 25px;
}
.blog-area.blog-grid .thumb-less .item::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: var(--dark-secondary);
  z-index: -1;
  opacity: 0.65;
}
.blog-area.blog-grid .thumb-less .item p {
  opacity: 0.9;
  transition: all 0.325s ease-in-out;
  transform: translateX(30px);
  opacity: 0;
  visibility: hidden;
}
.blog-area.blog-grid .thumb-less .item:hover p {
  visibility: visible;
  opacity: 0.9;
  transform: translateX(0);
}
.blog-area.blog-grid .thumb-less .author-meta {
  border-color: rgba(255, 255, 255, 0.4);
  padding-top: 22px;
  margin-top: 100px;
}
a.button-regular {
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: var(--color-paragraph);
  font-size: 18px;
  font-weight: 500;
}
a.button-regular i {
  margin-left: 5px;
  font-size: 13px;
  position: relative;
  top: 2px;
}
a.button-regular:hover {
  color: var(--color-primary);
}
.contact-style-one-info li {
  display: flex;
  margin-top: 30px;
}
.contact-style-one-info li i {
  display: inline-block;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  background: linear-gradient(180deg, #d5bbff 0%, #4787fa00 100%);
  border-radius: 5px;
  color: #6222cc;
  font-size: 25px;
  margin-right: 20px;
}
.contact-style-one-info li:nth-child(2) i {
  background: linear-gradient(180deg, #ffb6a0 0%, #4787fa00 100%);
  color: #ff5621;
}
.contact-style-one-info li:nth-child(3) i {
  background: linear-gradient(180deg, #72c22742 0%, #4787fa00 100%);
  color: #72c227;
}
.contact-style-one-info li p {
  margin: 0;
}
.contact-style-one-info li h4 {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 20px;
}
.contact-style-one-info li a {
  font-weight: 400;
}
.contact-style-one-info h2 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 1;
}
.contact-style-one-info ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 50px;
}
.contact-form-style-one {
  box-shadow: 0px 15px 60px -10px rgb(109 117 143 / 33%);
  padding: 60px 80px;
  border-radius: 10px;
  position: relative;
  background: var(--white);
}
.contact-form-style-one .illustration {
  position: absolute;
  left: -130px;
  bottom: -60px;
  z-index: -1;
}
.contact-form-style-one .sub-heading {
  margin-bottom: 15px;
}
.contact-form-style-one .heading {
  margin-bottom: 30px;
  font-size: 36px;
}
.contact-form-style-one input,
.contact-form-style-one textarea {
  margin-bottom: 15px;
  padding: 15px 20px;
  border: none;
  background: #f8f8f8;
}
.contact-form-style-one textarea {
  min-height: 180px;
}
.contact-form-style-one button {
  display: inline-block;
  font-weight: 500;
  text-transform: capitalize;
  transition: all 0.35s ease-in-out;
  overflow: hidden;
  border-radius: 3px;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  font-size: 17px;
  letter-spacing: 0;
  position: relative;
  z-index: 1;
  color: var(--white);
  border: none;
  background: var(--color-primary);
  padding: 16px 52px;
}
.contact-form-style-one button::after {
  position: absolute;
  top: inherit;
  right: inherit;
  bottom: inherit;
  left: -5%;
  content: "";
  height: 150px;
  width: 115%;
  z-index: -1;
  background-color: var(--dark);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-transform: translateY(-45%) skew(25deg) scale(0);
  transform: translateY(-45%) skew(25deg) scale(0);
}
.contact-form-style-one button:hover::after {
  -webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
  transform: translateY(-45%) skew(25deg) scale(1.2);
}
img.loader {
  margin-left: 8px;
}
.maps-area iframe {
  min-height: 550px;
  margin-bottom: -10px;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.error-box h1 {
  font-size: 200px;
  font-weight: 900;
  line-height: 150px;
}
.error-box h2 {
  font-weight: 600;
  margin-bottom: 25px;
}
footer {
  overflow: hidden;
}
footer .f-items {
  padding-top: 70px;
}
.footer-item {
  margin-top: 50px;
}
.footer-item .widget-title {
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 22px;
}
.footer-item .logo {
  height: 60px;
  margin-bottom: 30px;
}
.quick-contact {
  margin-top: 25px;
}
.quick-contact li {
  margin-top: 10px;
  position: relative;
}
.quick-contact li::after {
  position: absolute;
  right: -18px;
  top: 10px;
  content: "";
  height: 6px;
  width: 6px;
  background: #e7e7e7;
  z-index: -1;
  border-radius: 50%;
}
.quick-contact li:last-child::after {
  display: none;
}
.quick-contact li i {
  font-weight: 100;
  margin-right: 3px;
}
.quick-contact li:last-child {
  margin-right: 0;
}
.quick-contact li a {
  font-weight: 400;
}
.bg-dark .quick-contact li a,
.bg-theme .quick-contact li a,
.bg-gradient .quick-contact li a,
.shadow .quick-contact li a {
  color: #cccccc;
}
.bg-dark .quick-contact li a:hover,
.bg-theme .quick-contact li a:hover,
.bg-gradient .quick-contact li a:hover,
.shadow .quick-contact li a:hover {
  color: var(--white);
}
.footer-bottom li {
  display: inline-block;
}
.footer-bottom li a {
  color: var(--color-paragraph);
}
.footer-bottom li a:hover {
  color: var(--color-primary);
}
.bg-dark .footer-bottom li a,
.bg-theme .footer-bottom li a,
.bg-gradient .footer-bottom li a,
.shadow .footer-bottom li a {
  color: #cccccc;
}
.bg-dark .footer-bottom li a:hover,
.bg-theme .footer-bottom li a:hover,
.bg-gradient .footer-bottom li a:hover,
.shadow .footer-bottom li a:hover {
  color: var(--white);
}
.footer-bottom a {
  font-weight: 500;
}
.footer-bottom .text-end li {
  margin-left: 20px;
}
.footer-bottom p {
  margin: 0;
}
.bg-dark .footer-bottom p,
.bg-theme .footer-bottom p,
.bg-gradient .footer-bottom p,
.shadow .footer-bottom p {
  color: #cccccc;
}
.footer-bottom {
  padding: 20px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: var(--dark);
}
.footer-bottom a:hover {
  color: var(--color-primary);
}
.bg-dark .footer-bottom a:hover,
.bg-theme .footer-bottom a:hover,
.bg-gradient .footer-bottom a:hover,
.shadow .footer-bottom a:hover {
  color: var(--white);
}
.footer-item .link li {
  margin-top: 12px;
}
.footer-item .link li:first-child {
  margin-top: 0;
}
.footer-item .link li a {
  font-weight: 400;
  color: var(--color-paragraph);
}
.bg-dark .footer-item .link li a,
.bg-theme .footer-item .link li a,
.bg-gradient .footer-item .link li a,
.shadow .footer-item .link li a {
  color: #cccccc;
}
.footer-item .link li a:hover {
  color: var(--color-primary);
  margin-left: 8px;
}
.bg-dark .footer-item .link li a:hover,
.bg-theme .footer-item .link li a:hover,
.bg-gradient .footer-item .link li a:hover,
.shadow .footer-item .link li a:hover {
  opacity: 1;
  color: var(--white);
}
footer .footer-item p {
  color: var(--color-paragraph);
}
footer.bg-dark .footer-item p,
footer.bg-theme .footer-item p,
footer.bg-gradient .footer-item p,
footer.shadow .footer-item p {
  color: #cccccc;
}
footer .footer-social {
  margin-top: 30px;
}
footer .footer-social li {
  display: inline-block;
  margin-right: 10px;
}
footer .footer-social li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 43px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  font-size: 14px;
}
footer .footer-social li a:hover {
  background: var(--color-primary);
}
footer .address {
  padding-right: 35px;
}
footer .address li {
  margin-top: 20px;
  display: flex;
  color: #cccccc;
}
footer .address li a {
  font-weight: 400;
  color: #cccccc;
}
footer .address li a:hover {
  color: var(--white);
}
footer .address li strong {
  color: #ffffff;
  display: block;
  font-weight: 500;
  text-transform: uppercase;
}
footer .address li i {
  font-size: 30px;
  color: #ffffff;
  margin-right: 15px;
  position: relative;
  top: 5px;
}
footer .address li i.fal.fa-clock {
  font-size: 26px;
}
footer .newsletter {
  position: relative;
}
footer .newsletter form {
  margin-top: 30px;
}
footer .newsletter input,
footer .newsletter input:focus {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #ffffff;
}
footer .newsletter input::-webkit-input-placeholder,
footer .newsletter input:focus::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 15px;
  font-weight: 300;
}
footer .newsletter input::-moz-placeholder,
footer .newsletter input:focus::-moz-placeholder {
  color: #cccccc;
  font-size: 15px;
  font-weight: 300;
}
footer .newsletter input:-ms-input-placeholder,
footer .newsletter input:focus:-ms-input-placeholder {
  color: #cccccc;
  font-size: 15px;
  font-weight: 300;
}
footer .newsletter input:-moz-placeholder,
footer .newsletter input:focus:-moz-placeholder {
  color: #cccccc;
  font-size: 15px;
  font-weight: 300;
}
footer .newsletter button {
  border: none;
  padding: 11px;
  width: 100%;
  border-radius: 5px;
  margin-top: 15px;
  font-weight: 500;
  background: transparent;
  position: relative;
  z-index: 1;
  color: #ffffff;
}
footer .newsletter button i {
  font-weight: 100;
}
footer .newsletter button::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: var(--color-primary);
  z-index: -1;
  border-radius: 5px;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  background-size: 220% 150%;
}
footer .about {
  position: relative;
  z-index: 1;
  padding-right: 30px;
}
footer .about::after {
  position: absolute;
  right: 0;
  top: -300px;
  content: "";
  height: 400%;
  width: 500%;
  background: var(--color-primary);
  z-index: -1;
  opacity: 0.1;
}
.svg-shape svg.gray {
  fill: var(--bg-gray);
}
.svg-shape svg.white {
  fill: var(--white);
}
.svg-shape svg.dark {
  fill: var(--dark);
}
.svg-shape svg.theme {
  fill: var(--color-primary);
}
@media only screen and (min-width: 1200px) {
  .team-left-info {
    margin-top: -100px;
  }
}
@media only screen and (max-width: 1200px) {
  .team-style-one-item {
    background-image: none !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-style-two h2 {
    font-size: 50px;
  }
  .banner-style-two p {
    font-size: 18px;
  }
  .services-style-one .thumb {
    display: none;
  }
  .brand-items strong::after {
    display: none;
  }
  .brand-items strong {
    font-size: 20px;
  }
  .brand-items .align-center {
    align-items: center;
  }
  .team-single-area .team-content-top {
    bottom: 0;
    margin: 0;
    padding-bottom: 120px;
  }
  .team-single-area .team-content-top .right-info {
    margin: 0;
  }
  .blog-item-box.pl-50 {
    padding-left: 15px;
    margin-top: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .heading,
  .title {
    font-size: 36px;
    margin-top: 0;
    line-height: 1.2;
  }
  .banner-style-one .content .thumb img {
    max-width: 100%;
  }
  .banner-style-one .content .thumb {
    margin: 0;
    margin-top: 50px;
  }
  .banner-style-one .content .info {
    padding-bottom: 0;
  }
  .banner-style-one .content {
    padding-bottom: 120px;
    padding-top: 120px;
  }
  .banner-style-two-area {
    height: auto;
  }
  .banner-style-two-area div {
    height: auto;
  }
  .banner-style-two-area .content {
    padding-top: 120px;
  }
  .banner-style-two .thumb {
    float: none;
    text-align: center;
    margin: 50px auto auto;
    margin-bottom: 30px;
    position: relative;
  }
  .banner-style-two {
    text-align: center;
  }
  .banner-style-two .thumb img {
    max-width: 80%;
  }
  .banner-style-five .content {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .about-style-two .sub-item {
    display: none;
  }
  .about-style-one .thumb img:nth-child(2) {
    display: none;
  }
  .about-style-one .thumb img:nth-child(3) {
    margin-right: 30px;
    display: none;
  }
  .about-style-one .thumb img {
    margin-bottom: 40px;
  }
  .about-area.circle-shape-right-bottom {
    background: transparent !important;
  }
  .about-style-four .thumb img:nth-child(3) {
    display: none;
  }
  .about-style-four .thumb {
    margin-bottom: 50px;
  }
  .feature-style-one .item {
    background: var(--white);
  }
  .brand-area {
    text-align: center;
  }
  .fun-factor-style-one .item {
    margin-top: 30px;
  }
  .brand3col {
    margin-top: 30px;
  }
  .brand-items img {
    padding: 0 15px;
  }
  .brand-items .shape-text svg {
    opacity: 0.3;
  }
  .fun-factor-style-one {
    margin-top: 20px;
  }
  .feature-style-one .item {
    text-align: center;
  }
  .animate-illustration .sub-item img:first-child {
    right: 50px;
    top: 0;
  }
  .animate-illustration .sub-item img:nth-child(2) {
    right: 50px;
    bottom: 50px;
  }
  .animate-illustration .sub-item img:nth-child(3) {
    top: 0;
    left: 40px;
  }
  .animate-illustration .sub-item img:nth-child(4) {
    margin-left: -76px;
    bottom: 0;
  }
  .animate-illustration .sub-item img:nth-child(5) {
    left: 30px;
    bottom: 60px;
  }
  .animate-illustration .sub-item img:nth-child(6) {
    margin-bottom: -60px;
    margin-left: -50px;
  }
  .choose-us-style-one .animate-illustration {
    margin-bottom: 50px;
  }
  .team-single-area .team-content-top .right-info {
    padding: 0 15px;
    margin-top: 30px;
    margin-bottom: 120px;
  }
  .team-single-area .team-content-top {
    margin: 0;
    bottom: 0;
  }
  .team-single-area .bottom-info .skill-items {
    padding-left: 0;
    margin-top: 30px;
  }
  .pricing-style-one {
    margin-top: 30px;
  }
  .pricing-style-one-box {
    margin-top: -30px;
  }
  .faq-sahpe {
    display: none;
  }
  .process-style-one-box {
    margin-top: -50px;
  }
  .process-style-one:first-child {
    margin-top: 50px;
  }
  .process-style-one .icon {
    margin-bottom: 20px;
  }
  .process-style-one:nth-child(2n) {
    position: relative;
  }
  .process-style-one:nth-child(2n) .icon {
    height: 150px;
    width: 150px;
    line-height: 150px;
    margin-bottom: 45px;
  }
  .process-style-one:nth-child(2n) .icon img {
    height: 60px;
  }
  .process-style-one:nth-child(2n) h4 {
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .process-style-one {
    margin-top: 50px;
  }
  .process-style-one:nth-child(3) {
    margin-top: 50px;
  }
  .process-style-one:nth-child(2n) .icon span {
    top: auto;
    bottom: 15px;
    right: -5px;
  }
  .process-style-two-list {
    height: auto;
    width: auto;
    margin-bottom: 40px;
    display: none;
  }
  .process-style-two-thumb {
    margin-bottom: 50px;
  }
  .services-tab-navs .nav-tabs .nav-link::after {
    background: var(--color-primary);
    right: 0;
  }
  .services-tab-navs .nav-tabs .nav-link.active::after {
    width: 100%;
  }
  .services-style-one .tab-content .thumb img {
    margin: auto;
  }
  .services-style-one .tab-content .thumb {
    margin-bottom: 50px;
  }
  .services-style-one-area .default-padding-top {
    padding-top: 50px;
  }
  .services-tab-navs .nav-tabs .nav-link::before {
    display: none;
  }
  .services-tab-navs .nav-tabs .nav-link {
    padding: 25px;
    border-bottom: 1px solid #dddddd;
  }
  .services-tab-navs .nav-tabs {
    padding-top: 40px;
    padding-bottom: 60px;
  }
  .services-style-two .item {
    text-align: center;
    padding-bottom: 75px;
  }
  .services-style-two .item .btn-icon {
    left: 50%;
    transform: translateX(-50%);
    padding-left: 0;
    border: none;
    opacity: 1;
    visibility: visible;
    bottom: 45px;
  }
  .services-style-two .item:hover .btn-icon,
  .services-style-two:first-child .item .btn-icon {
    left: 50%;
    transform: translateX(-50%);
  }
  .services-style-one .thumb::after {
    top: 0;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
  .shape-animation {
    left: auto;
    right: 50px;
  }
  .rating-provider {
    margin-bottom: 50px;
  }
  .project-more-info .btn {
    float: left;
    margin-top: 20px;
  }
  .project-info {
    position: inherit;
    transform: inherit;
    left: inherit;
    right: inherit;
    top: 0;
    bottom: inherit;
    max-width: 100%;
  }
  .seo-score h2 {
    font-size: 36px;
    line-height: 1.2;
  }
  .team-grid:last-child {
    margin-top: 60px;
  }
  .contact-form-style-one {
    margin-top: 50px;
  }
  .contact-style-one-info {
    text-align: center;
  }
  .contact-style-one-info ul {
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }
  .contact-style-one-info li {
    display: block;
    margin: 0;
  }
  .contact-style-one-info li i {
    margin: 0;
    margin-bottom: 25px;
  }
  .maps-area iframe {
    min-height: 450px;
  }
  .footer-bottom .text-end {
    text-align: center !important;
    margin-top: 10px;
  }
  .footer-bottom {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .title {
    font-size: 36px;
    line-height: 1.2;
  }
  .heading {
    font-size: 36px;
    margin-top: 0;
    line-height: 1.2;
  }
  .banner-style-one .content {
    padding-top: 60px;
    padding-bottom: 60px;
    text-align: center;
  }
  .banner-style-one h2 {
    font-size: 36px;
    line-height: 1.2;
  }
  .banner-style-one .content .thumb img {
    max-width: 80%;
    margin: auto;
  }
  .banner-style-one .content .thumb {
    margin: 0;
    margin-top: 30px;
  }
  .banner-style-one .content .info {
    padding: 0;
  }
  .banner-style-one a {
    margin: 0;
  }
  .banner-style-one a.btn-regular {
    display: none;
  }
  .banner-style-two-area {
    height: auto;
  }
  .banner-style-two-area div {
    height: auto;
  }
  .banner-style-two-area .content {
    padding-top: 60px;
    text-align: center;
  }
  .banner-style-two h2 {
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .banner-style-two strong::after {
    background-size: contain;
    bottom: -10px;
    display: none;
  }
  .banner-style-two .thumb .social img {
    display: none;
  }
  .banner-style-two .thumb {
    max-width: 80%;
    margin: 50px auto auto;
    float: none;
  }
  .banner-style-two .thumb::after {
    left: 0;
    bottom: 0;
    top: -20px;
    left: -15px;
    display: none;
  }
  .shape-text .shape {
    display: none;
  }
  .shape-left-bottom {
    display: none;
  }
  .banner-style-five h2 {
    font-size: 36px;
    line-height: 1.2;
    margin-top: 0;
  }
  .banner-style-five .content {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .banner-style-five-thumb img:nth-child(2) {
    display: none;
  }
  .banner-style-five-thumb img:nth-child(3) {
    display: none;
  }
  .banner-style-five-thumb img:first-child {
    width: 100%;
    max-width: 100%;
    z-index: 1;
    margin-top: 50px;
  }
  .banner-style-five-thumb .shape {
    display: none;
  }
  .about-style-two .sub-item {
    display: none;
  }
  .about-style-one .thumb img:first-child {
    float: none;
  }
  .about-style-one .thumb img:nth-child(2) {
    display: none;
  }
  .about-style-one .thumb img:nth-child(3) {
    margin-right: 30px;
    display: none;
  }
  .about-area.circle-shape-right-bottom {
    background: transparent !important;
  }
  .about-style-one .thumb {
    margin-bottom: 30px;
  }
  .about-style-four .thumb img:first-child {
    max-width: 100%;
  }
  .about-style-four .thumb img:nth-child(2) {
    display: none;
  }
  .about-style-four .thumb .icon {
    right: 0;
    z-index: 1;
    top: -30px;
    display: none;
  }
  .about-style-four .thumb {
    padding: 0;
    margin-bottom: 40px;
    text-align: center;
    margin-top: 0;
    max-width: 100%;
  }
  .about-style-four .thumb img:nth-child(3) {
    left: 0;
    display: none;
  }
  .about-style-four .thumb img:nth-child(4) {
    right: 0;
    display: none;
  }
  .brand-area {
    text-align: center;
  }
  .brand-items strong {
    font-size: 20px;
  }
  .brand-items strong::after {
    display: none;
  }
  .fun-factor-style-one .item {
    margin-top: 30px;
  }
  .fun-factor-style-one .item:first-child {
    margin-top: 0;
  }
  .brand3col {
    margin-top: 30px;
  }
  .brand-items img {
    padding: 0 15px;
  }
  .feature-style-one .item {
    text-align: center;
  }
  .services-tab-navs .nav-tabs .nav-link::after {
    background: var(--color-primary);
    right: 0;
  }
  .services-tab-navs .nav-tabs .nav-link.active::after {
    width: 100%;
  }
  .services-tab-navs .nav-tabs .nav-link i {
    position: relative;
    margin: 0;
    margin-bottom: 25px;
    top: 15px;
  }
  .services-style-one .tab-content .thumb img {
    margin: auto;
  }
  .services-style-one .thumb::after {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
  }
  .services-style-one .tab-content .thumb {
    margin-bottom: 30px;
  }
  .services-tab-navs .nav-tabs .nav-link::before {
    display: none;
  }
  .services-tab-navs .nav-tabs .nav-link {
    display: block;
    text-align: center;
    padding: 25px;
    border-bottom: 1px solid #dddddd;
  }
  .services-tab-navs .nav-tabs .nav-link b {
    display: none;
  }
  .services-tab-navs .nav-tabs {
    padding-top: 40px;
    padding-bottom: 60px;
  }
  .services-style-two .item {
    text-align: center;
    padding-bottom: 75px;
  }
  .services-style-two .item .btn-icon {
    left: 50%;
    transform: translateX(-50%);
    padding-left: 0;
    border: none;
    opacity: 1;
    visibility: visible;
    bottom: 45px;
  }
  .services-style-two .item:hover .btn-icon,
  .services-style-two:first-child .item .btn-icon {
    left: 50%;
    transform: translateX(-50%);
  }
  .shape-animation-up-down {
    display: none;
  }
  .services-style-two-area {
    padding-top: 100px !important;
  }
  .pricing-style-one-item > i {
    display: none;
  }
  .seo-score h2 {
    font-size: 36px;
    line-height: 1.2;
  }
  .seo-score-area .seo-score {
    padding: 50px 30px;
  }
  .faq-sahpe {
    display: none;
  }
  .project-more-info .btn {
    float: left;
    margin-top: 15px;
  }
  .project-info {
    position: inherit;
    transform: inherit;
    left: inherit;
    right: inherit;
    top: 0;
    bottom: inherit;
    max-width: 100%;
  }
  .project-details-area .check-list {
    grid-template-columns: 2fr;
  }
  .shape-right-top {
    z-index: -1;
    opacity: 0.3;
  }
  .project-details-area .check-list h4 {
    font-size: 20px;
    padding-left: 40px;
  }
  .project-details-area .check-list h4::after {
    height: 25px;
    width: 25px;
    line-height: 25px;
  }
  .animate-illustration .sub-item img:nth-child(2) {
    display: none;
  }
  .animate-illustration .sub-item img:nth-child(4) {
    margin-left: 45px;
    bottom: 20px;
  }
  .animate-illustration .sub-item img:first-child {
    top: 0;
    right: 0;
  }
  .animate-illustration .sub-item img:nth-child(6) {
    display: none;
  }
  .choose-us-style-one ul li:nth-child(2) {
    display: none;
  }
  .choose-us-style-one ul {
    display: block;
  }
  .choose-us-area {
    background-image: none !important;
  }
  .process-style-one:first-child {
    margin-top: 0;
  }
  .process-style-one .icon {
    margin-bottom: 20px;
  }
  .process-style-one:nth-child(2n) {
    position: relative;
  }
  .process-style-one:nth-child(2n) .icon {
    height: 150px;
    width: 150px;
    line-height: 150px;
    margin-bottom: 45px;
  }
  .process-style-one:nth-child(2n) .icon img {
    height: 60px;
  }
  .process-style-one:nth-child(2n) h4 {
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .process-style-one {
    margin-top: 50px;
  }
  .process-style-one:nth-child(3) {
    margin-top: 50px;
  }
  .process-style-one:nth-child(2n) .icon span {
    top: auto;
    bottom: 15px;
    right: -5px;
  }
  .process-style-two-list {
    height: auto;
    width: auto;
    margin-bottom: 40px;
    display: none;
  }
  .process-style-two-thumb {
    margin: 0;
    margin-bottom: 50px;
  }
  .boosting-style-one {
    text-align: center;
  }
  ul.list-icon li {
    display: block;
    text-align: center;
  }
  ul.list-icon li .icon {
    margin: 0;
    margin-bottom: 15px;
  }
  .boosting-items {
    padding: 50px;
  }
  .boosting-items::after {
    display: none;
  }
  .team-grid:last-child {
    margin-top: 30px;
  }
  .team-single-area .team-content-top .right-info {
    padding: 0 15px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .team-single-area .team-content-top {
    margin: 0;
    bottom: 0;
  }
  .team-single-area .bottom-info .skill-items {
    padding-left: 0;
    margin-top: 30px;
  }
  .pricing-style-one {
    margin-top: 30px;
  }
  .pricing-style-one:first-child {
    margin-top: 0;
  }
  .testimonial-style-two-area {
    overflow: hidden;
  }
  .shape-animation {
    left: auto;
    right: 20px;
    top: 20px;
  }
  .rating-provider {
    margin-bottom: 40px;
  }
  .testimonial-style-two .rating-provider .ratings {
    margin: 0;
    margin-bottom: 25px;
  }
  .contact-form-style-one {
    margin-top: 50px;
  }
  .contact-style-one-info {
    text-align: center;
  }
  .contact-style-one-info li {
    display: block;
  }
  .contact-style-one-info li i {
    margin: 0;
    margin-bottom: 25px;
  }
  .maps-area iframe {
    min-height: 300px;
  }
  footer .f-items {
    padding-top: 0;
  }
  .footer-bottom .text-end {
    text-align: center !important;
    margin-top: 10px;
  }
  .footer-bottom {
    text-align: center;
  }
  footer .about::after {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 767px) {
  .gallery-items.colums-2 .pf-item,
  .gallery-items.colums-3 .pf-item {
    width: 50%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .gallery-items.colums-4 .pf-item {
    width: 50%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
}
@media screen and (max-width: 991px) {
  .order-last {
    order: 0;
  }
  .footer-bottom .text-end li {
    margin: 0 10px;
  }
  .pricing-style-three .row > div {
    padding-left: 12px;
  }
}
@media (max-width: 450px) {
  .animate-illustration .sub-item img:nth-child(2) {
    display: none;
  }
  .animate-illustration .sub-item img:nth-child(4) {
    margin-left: -20px;
    bottom: 13px;
    display: none;
  }
  .animate-illustration .sub-item img:first-child {
    top: 0;
    right: 0;
  }
  .animate-illustration .sub-item img:nth-child(6) {
    display: none;
  }
  .animate-illustration .sub-item img:nth-child(3) {
    display: none;
  }
  .team-single-area .right-info .social .share-link {
    display: none;
  }
  .pricing-style-one-item {
    padding: 50px 30px;
  }
  .rating-provider {
    display: block;
  }
  .rating-provider .ratings {
    display: block;
    float: left;
    width: 100%;
    margin: 0;
    margin-top: 25px;
    margin-bottom: 30px;
  }
  .project-info .project-basic-info {
    grid-template-columns: 2fr;
  }
  .project-info ul.social li:nth-child(4) {
    display: none;
  }
  .error-page-area .error-box h1 {
    font-size: 150px;
    letter-spacing: 0;
    line-height: 120px;
  }
  .contact-form-style-one {
    padding: 50px 30px;
  }
}
.ambrox-preloader {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9000;
}
.ambrox-preloader .animation-preloader {
  z-index: 1000;
}
.ambrox-preloader .animation-preloader .spinner {
  -webkit-animation: spinner 1s infinite linear;
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  border: 3px solid rgba(2, 109, 255, 0.2);
  border-top-color: var(--color-primary);
  height: 110px;
  margin: 0 auto 30px auto;
  width: 110px;
}
.ambrox-preloader .animation-preloader .txt-loading {
  font: bold 4em "Yantramanav", sans-serif;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ambrox-preloader .animation-preloader .txt-loading .letters-loading {
  color: rgba(0, 0, 0, 0.2);
  position: relative;
}
.ambrox-preloader .animation-preloader .txt-loading .letters-loading:before {
  -webkit-animation: letters-loading 4s infinite;
  animation: letters-loading 4s infinite;
  color: #000000;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  font-family: "Yantramanav", sans-serif;
  position: absolute;
  top: -3px;
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
}
.ambrox-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(2):before {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.ambrox-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(3):before {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.ambrox-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(4):before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.ambrox-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(5):before {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.ambrox-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(6):before {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.ambrox-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(7):before {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}
.ambrox-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(8):before {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}
.ambrox-preloader.dark .animation-preloader .spinner {
  border-color: rgba(255, 255, 255, 0.2);
  border-top-color: #fff;
}
.ambrox-preloader.dark .animation-preloader .txt-loading .letters-loading {
  color: rgba(255, 255, 255, 0.2);
}
.ambrox-preloader.dark
  .animation-preloader
  .txt-loading
  .letters-loading:before {
  color: #fff;
}
.ambrox-preloader p {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 8px;
  color: #3b3b3b;
}
.ambrox-preloader .loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  z-index: 1;
  pointer-events: none;
}
.ambrox-preloader .loader .row {
  height: 100%;
}
.ambrox-preloader .loader .loader-section {
  padding: 0px;
}
.ambrox-preloader .loader .loader-section .bg {
  background-color: #ffffff;
  height: 100%;
  left: 0;
  width: 100%;
  -webkit-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
}
.ambrox-preloader .loader.dark_bg .loader-section .bg {
  background: #111339;
}
.ambrox-preloader.loaded .animation-preloader {
  opacity: 0;
  -webkit-transition: 0.3s ease-out;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}
.ambrox-preloader.loaded .loader-section .bg {
  width: 0;
  -webkit-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
  -o-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
  transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
}
@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}
@keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}
@-webkit-keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
@media screen and (max-width: 767px) {
  .ambrox-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
  .ambrox-preloader .animation-preloader .txt-loading {
    font: bold 3.5em "Yantramanav", sans-serif;
  }
}
@media screen and (max-width: 500px) {
  .ambrox-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }
  .ambrox-preloader .animation-preloader .txt-loading {
    font: bold 2em "Yantramanav", sans-serif;
  }
}
@media (min-width: 1200px) {
  .brand-style-bg .container-fill {
    padding-left: 0;
    padding-right: 50px;
  }
  .brand-style-bg a.navbar-brand {
    padding: 0 50px;
  }
  .navbar.brand-style-bg .navbar-header::before,
  .navbar.brand-style-bg .navbar-header::after {
    display: none;
  }
  .top-bar .container-fill {
    padding: 0 50px;
  }
  .navbar.brand-style-bg .navbar-header {
    padding: 0;
    top: inherit;
    margin: 0;
  }
}
.top-bar .social li {
  display: inline-block;
}
.top-bar .text-end .social li {
  margin-left: 30px;
  margin-right: 0;
}
.top-bar {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 10px 0;
}
ul.topbar-list {
  display: inline-block;
  position: relative;
  z-index: 1;
}
ul.topbar-list::after {
  position: absolute;
  right: -30px;
  top: -15px;
  content: "";
  bottom: -15px;
  background: var(--color-primary);
  width: 500%;
  z-index: -1;
  transform: skewX(-15deg);
}
.topbar-list li {
  display: inline-block;
  margin-right: 30px;
  border-right: 1px solid rgba(255, 255, 255, 0.6);
  padding-right: 30px;
}
.topbar-list li:last-child {
  margin-right: 0;
  padding-right: 0;
  border: none;
}
.banner-shape-bg {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  z-index: 1;
  transform: translateX(-50%);
}
.banner-shape-bg img {
  height: 100%;
  width: auto;
}
.banner-style-business h4 {
  text-transform: uppercase;
  font-size: 24px;
}
.banner-style-business h2 {
  font-size: 80px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 30px;
}
.banner-style-business h2 strong {
  display: block;
  font-weight: 200;
}
.banner-style-business p {
  font-size: 18px;
  font-weight: 400;
}
.banner-area.banner-business .swiper-slide.swiper-slide-active .button {
  -webkit-transition-delay: 2400ms;
  transition-delay: 2400ms;
}
.banner-style-business .button {
  margin-top: 40px;
}
.about-style-five .fun-fact .counter {
  justify-content: left;
  font-size: 120px;
  color: transparent;
  -webkit-text-stroke: 1px var(--color-primary);
  line-height: 100px;
  margin-bottom: 15px;
}
.about-style-five .fun-fact {
  text-align: left;
}
.about-style-five .fun-fact span.medium {
  font-size: 30px;
  color: var(--color-heading);
  padding-right: 30%;
  display: block;
  line-height: 1.2;
}
ul.financial-step {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  margin-top: 30px;
}
ul.financial-step li {
  display: flex;
}
ul.financial-step li span {
  display: inline-block;
  font-size: 60px;
  font-weight: 700;
  min-width: 80px;
  color: var(--color-primary);
  line-height: 45px;
}
ul.financial-step li h4 {
  margin: 0;
  font-size: 18px;
}
.about-style-five .title strong {
  font-weight: 600;
  position: relative;
  z-index: 1;
  display: inline-block;
}
.about-style-five .title strong::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../Images/shape/b1.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  z-index: -1;
}
.about-style-five.right-border {
  padding-left: 80px;
  border-left: 2px solid;
}
@media only screen and (min-width: 1200px) {
  .services-style-five-area {
    max-width: 90%;
    margin: auto;
    border-radius: 10px;
  }
}
.services-style-five-area {
  overflow: hidden;
}
.services-style-five {
  padding: 60px 30px;
  background: var(--white);
  border-radius: 10px;
  padding-bottom: 0;
  transition: all 0.35s ease-in-out;
}
.services-style-five img {
  height: 80px;
  margin-bottom: 30px;
}
.services-style-five > a {
  display: inline-block;
  background: var(--bg-gradient);
  padding: 7px 45px;
  padding-top: 9px;
  border-radius: 30px;
  font-weight: 500;
  margin-top: 15px;
  color: var(--white);
}
.services-style-five p {
  opacity: 0.9;
}
.services-style-five > a {
  opacity: 0;
  transform: translateY(-30px);
}
.services-style-five.active > a,
.services-style-five:hover > a {
  transform: translateY(0);
  opacity: 1;
}
.services-style-five.active,
.services-style-five:hover {
  padding-bottom: 60px;
}
.business-progress-thumb img {
  margin-left: -10%;
  max-width: 110%;
}
.list-grid-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  margin-top: 30px;
}
.list-grid-info h4 {
  font-weight: 600;
}
.list-grid-info li {
  position: relative;
  z-index: 1;
  padding-left: 25px;
}
.list-grid-info li::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-weight: 100;
}
.list-grid-info li:first-child::after {
  display: none;
}
.list-grid-info li:first-child {
  padding-left: 0;
}
.business-progress-info h2 strong {
  display: inline-block;
  font-weight: 600;
  position: relative;
  z-index: 1;
}
.business-progress-info h2 strong::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  height: 110%;
  width: 110%;
  background: url(../Images/shape/19.png);
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: translate(-50%, -50%);
}
.business-progress-thumb {
  position: relative;
}
.business-progress-thumb .fun-fact {
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-primary);
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 50px;
  animation: UpDown 5s linear infinite;
}
.business-progress-thumb .fun-fact .counter {
  color: var(--white);
  margin: 0;
}
.business-progress-thumb .fun-fact .medium {
  color: var(--white);
  font-weight: 400;
}
.business-progress-thumb .fun-fact::after {
  position: absolute;
  left: 27%;
  bottom: -15px;
  content: "";
  border-left: 3px solid transparent;
  border-right: 30px solid transparent;
  border-top: 25px solid var(--color-primary);
  transform: rotate(7deg);
}
.skill-items.style-two .progress-box .progress {
  background: #e2e3ff;
  border-radius: 30px;
  margin-top: 15px;
  height: 15px;
  border: 1px solid #b7c9e1;
}
.skill-items.style-two .progress-box .progress .progress-bar {
  top: 0;
  height: 15px;
  left: 0;
}
.skill-items.style-two .progress-box .progress .progress-bar span {
  top: -40px;
}
.portfolio-items.colums-2 .pf-item {
  float: left;
  width: 50%;
}
.portfolio-items {
  margin: -15px;
}
.portfolio-items .pf-item {
  padding: 15px;
}
.mix-item-menu button {
  float: left;
  padding: 14px 40px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 30px;
  margin-right: 10px;
  margin-top: 10px;
  border: 1px solid #c3cbe8;
  transition: all 0.35s ease-in-out;
}
.mix-item-menu button.active {
  background: var(--color-primary);
  color: var(--white);
}
.pf-item .thumb {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.pf-item .thumb a {
  display: inline-block;
  height: 50px;
  width: 50px;
  background: var(--white);
  color: var(--color-primary);
  font-weight: 100;
  text-align: center;
  line-height: 55px;
  border-radius: 50%;
  position: absolute;
  left: 60px;
  bottom: 30px;
  font-size: 20px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}
.pf-item .thumb a i {
  font-weight: 400;
}
.pf-item .thumb::after {
  position: absolute;
  left: -50px;
  bottom: -100px;
  content: "";
  height: 200px;
  width: 200px;
  background: var(--bg-gradient);
  border-radius: 50%;
  transition: all 0.35s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.pf-item:hover .thumb::after {
  bottom: -50px;
  opacity: 1;
  visibility: visible;
}
.pf-item:hover .thumb a {
  left: 30px;
  opacity: 1;
  visibility: visible;
}
.pf-item .thumb img {
  transition: all 0.35s ease-in-out;
}
.pf-item:hover .thumb img {
  transform: scale(1.2);
}
.pf-item .info {
  margin-top: 15px;
}
.pf-item .info h4 {
  margin-bottom: 3px;
}
.pf-item .info .cat span {
  display: inline-block;
  color: var(--color-primary);
  position: relative;
  z-index: 1;
  margin-right: 15px;
}
.pf-item .info .cat span::after {
  position: absolute;
  right: -12px;
  top: 50%;
  content: "";
  height: 5px;
  width: 5px;
  background: var(--color-primary);
  border-radius: 50%;
  margin-top: -2px;
}
.pf-item .info .cat span:last-child::after {
  display: none;
}
.team-style-two .info ul li {
  display: inline-block;
}
.team-style-two .info li a {
  display: inline-block;
  height: 45px;
  width: 45px;
  line-height: 50px;
  text-align: center;
  background: var(--color-primary);
  border-radius: 50%;
  color: var(--white);
  margin-right: 7px;
}
.team-style-two .info h4 {
  margin: 0;
}
.team-style-two .info {
  margin-top: 20px;
}
.team-style-two .thumb {
  position: relative;
}
.team-style-two .thumb a {
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background: var(--color-primary);
  text-align: center;
  color: var(--white);
  position: absolute;
  right: 30px;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
}
.team-style-two .thumb a:nth-child(2) {
  bottom: 65px;
  background: var(--dark);
}
.team-style-two:hover .thumb a {
  opacity: 1;
  visibility: visible;
  bottom: 30px;
}
.team-style-two:hover .thumb a:nth-child(2) {
  bottom: 85px;
}
.faq-style-two-items {
  padding: 120px 0;
  padding-right: 100px;
  position: relative;
  z-index: 1;
}
.faq-style-two-area {
  overflow: hidden;
}
.faq-style-two-items::after {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 500%;
  background: var(--dark);
  z-index: -1;
}
.text-light .faq-style-one button.accordion-button {
  color: var(--white);
  border-color: rgba(255, 255, 255, 0.5) !important;
  border-width: 1px !important;
}
.text-light .faq-style-one .accordion-body p {
  opacity: 0.9;
}
a.btn-chat {
  display: inline-block;
  height: 80px;
  width: 80px;
  line-height: 85px;
  text-align: center;
  background: var(--bg-gradient);
  border-radius: 50%;
  font-weight: 100;
  font-size: 30px;
  margin-top: 15px;
}
.faq-style-two-items p {
  opacity: 0.9;
}
.chat-badge img {
  width: 40px;
  transform: rotate(100deg);
}
.chat-badge {
  position: absolute;
  left: 100px;
  margin-top: -50px;
}
.chat-badge span {
  margin-left: 5px;
}
@media (min-width: 1024px) {
  .navbar.no-background.dark .attr-nav .side-menu span {
    background-color: var(--color-heading);
  }
}
@media (min-width: 1200px) {
  nav.navbar-border {
    width: 90% !important;
    left: 5%;
  }
  nav.navbar-border .container-fill {
    padding: 0;
  }
  .banner-area.double-items.bottom-thumb .content {
    margin-top: -110px;
  }
}
.banner-style-six-area {
  overflow: hidden;
}
.banner-style-six h4 {
  text-transform: uppercase;
  font-size: 24px;
  color: var(--color-primary);
  font-weight: 500;
  background: var(--bg-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.banner-style-six h2 {
  font-size: 80px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 30px;
}
.banner-style-six h2 strong {
  display: block;
  font-weight: 200;
}
.banner-style-six p {
  font-size: 18px;
  font-weight: 400;
}
.banner-area.banner-style-six-area .swiper-slide.swiper-slide-active .button {
  -webkit-transition-delay: 2400ms;
  transition-delay: 2400ms;
}
.banner-style-six .button {
  margin-top: 40px;
}
.banner-style-six-area {
  padding-top: 160px;
}
.banner-shape-six {
  position: absolute;
  left: 0;
  top: 0;
  height: 100% !important;
  width: 100%;
  background-size: cover;
  background-position: center;
}
.banner-area.banner-style-six-area .swiper-button-prev::after,
.banner-area.banner-style-six-area .swiper-button-next::after {
  color: var(--color-heading);
  font-size: 16px;
}
.banner-area.banner-style-six-area.navigation-circle .swiper-button-prev,
.banner-area.banner-style-six-area.navigation-circle .swiper-button-next {
  background: var(--white);
  height: 55px;
  width: 55px;
  line-height: 55px;
  box-shadow: 0 0 10px #cccccc;
}
.service-six-item {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  position: relative;
  margin-top: 50px;
}
.service-six-item i {
  display: inline-block;
  height: 80px;
  width: 80px;
  line-height: 80px;
  background: var(--dark);
  text-align: center;
  color: var(--white);
  font-size: 40px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
}
.service-six-item i::before {
  line-height: inherit;
}
.service-six-item p {
  margin: 0;
}
.service-six-heading {
  height: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-position: center;
  margin-right: 30px;
  position: relative;
  z-index: 1;
}
.service-six-heading .sub-title {
  background: transparent;
  -webkit-text-fill-color: var(--white);
}
.service-six-heading .heading {
  font-size: 42px;
  margin: 0;
  font-weight: 500;
}
.service-six-heading img {
  position: absolute;
  z-index: -1;
  left: -100px;
  top: 100px;
  width: 200px;
}
.about-style-six-area {
  background-size: cover;
  background-position: right !important;
}
.about-style-six {
  padding: 120px;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: right bottom;
}
.about-style-six h2 {
  font-weight: 600;
}
.about-style-six-counter {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
ul.counter-list {
  display: flex;
  align-items: center;
  justify-content: end;
}
ul.counter-list li {
  background: var(--color-primary);
  padding: 50px;
}
ul.counter-list li:first-child {
  background: #008cff;
}
.about-style-six-counter {
  position: relative;
  right: -80px;
  margin-left: -100px;
}
ul.counter-list li .counter {
  color: var(--white);
}
ul.counter-list li .medium {
  color: var(--white);
}
.about-style-six .sub-title {
  font-weight: 900;
}
.text-light .list-standard li::after {
  color: var(--white);
  border-color: rgba(255, 255, 255, 0.5);
  font-weight: 100;
}
ul.shor-contact li {
  display: flex;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 20px;
  padding-top: 20px;
}
ul.shor-contact li i {
  min-width: 75px;
  font-size: 50px;
  color: var(--color-primary);
}
ul.shor-contact {
  margin-top: 35px;
}
ul.shor-contact li span {
  display: block;
  font-weight: 600;
  text-transform: uppercase;
}
ul.shor-contact li:first-child {
  margin: 0;
  padding: 0;
  border: none;
}
.about-style-six.bg-dark p {
  opacity: 0.85;
}
.about-style-six .title {
  margin-bottom: 30px;
}
.expertise-area .skill-items .progress-box {
  margin-bottom: 30px;
}
.skill-items.style-two .progress-box .progress {
  height: 7px;
  border: none;
}
.skill-items.style-two .progress-box .progress .progress-bar {
  height: 10px;
  top: -2px;
}
.skill-items.style-two .progress-box .progress .progress-bar::after {
  position: absolute;
  right: 0;
  top: -5px;
  content: "";
  height: 20px;
  width: 20px;
  background: var(--white);
  border-radius: 50%;
  border: 3px solid var(--color-primary);
}
.expertise-item {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  overflow: hidden;
}
.expertise-item h4 {
  font-weight: 600;
  font-size: 22px;
}
.expertise-item .content {
  padding: 37px;
}
.expertise-item .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e7e7e7;
  padding-left: 37px;
}
.expertise-item .bottom a {
  display: inline-block;
  height: 85px;
  width: 70px;
  text-align: center;
  line-height: 85px;
  border-left: 1px solid #e7e7e7;
}
.expertise-item .bottom > i {
  display: inline-block;
  font-size: 50px;
  color: var(--color-heading);
  font-weight: 100;
}
.expertise-item p {
  margin: 0;
}
@media only screen and (min-width: 1250px) {
  .container-stage {
    margin-left: calc((100% - 1250px) / 2);
    width: auto;
    min-width: auto;
    max-width: inherit;
    padding-right: 0;
  }
}
.project-style-two-area {
  position: relative;
  z-index: 1;
}
.project-style-two-area::after {
  position: absolute;
  left: -10%;
  top: 0;
  content: "";
  height: 50%;
  width: 70%;
  background: var(--color-primary);
  z-index: -1;
  transform: skewX(-10deg);
}
.text-light .sub-title {
  background: transparent;
  -webkit-text-fill-color: var(--white);
  opacity: 0.9;
}
.project-two-swiper-nav {
  position: relative;
  width: 100%;
  top: 0;
}
.project-two-swiper-nav .swiper-pagination {
  position: absolute;
  left: auto;
  right: 0;
  width: auto;
  bottom: 60px;
}
.project-two-swiper-nav .swiper-pagination span {
  height: 24px;
  width: 24px;
  background: transparent;
  border: 2px solid var(--color-primary);
  position: relative;
}
.project-two-swiper-nav
  .swiper-pagination
  span.swiper-pagination-bullet-active::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  height: 8px;
  width: 8px;
  background: var(--color-primary);
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.project-style-two {
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.project-style-two .content {
  position: absolute;
  z-index: 1;
  display: inline-block;
  left: 45px;
  bottom: 30px;
  right: 45px;
  padding-top: 25px;
}
.project-style-two .content::after {
  position: absolute;
  left: 0;
  top: 0;
  height: 1px;
  width: 0;
  background: var(--white);
  content: "";
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s ease-in-out;
}
.project-style-two:hover .content::after {
  width: 100%;
  opacity: 1;
  visibility: visible;
  transition-delay: 0.4s;
}
.project-style-two .content h4 a {
  color: var(--white);
  position: relative;
  left: -30px;
  opacity: 0;
  visibility: hidden;
}
.project-style-two .content h4 {
  margin-bottom: 5px;
  transition: all 0.35s ease-in-out;
}
.project-style-two .content span {
  text-transform: uppercase;
  color: var(--white);
  font-size: 13px;
  transition: all 0.35s ease-in-out;
  position: relative;
  right: -30px;
  opacity: 0;
  visibility: hidden;
}
.project-style-two::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  height: 0;
  width: 0;
  background: rgba(0, 0, 0, 0)
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 70%) repeat
    scroll 0 0;
  opacity: 0;
  transition: all 0.45s ease-in-out;
  visibility: hidden;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.project-style-two:hover::after {
  width: 800px;
  height: 800px;
  opacity: 0.7;
  visibility: visible;
}
.project-style-two:hover .content h4 a {
  left: 0;
  transition-delay: 0.3s;
  opacity: 1;
  visibility: visible;
}
.project-style-two:hover .content span {
  right: 0;
  opacity: 1;
  visibility: visible;
  transition-delay: 0.4s;
}
.project-style-two img {
  transition: all 0.35s ease-in-out;
}
.project-style-two:hover img {
  transform: scale(1.2);
}
.process-style-one .icon span {
  color: var(--white);
}
.process-style-one-box.style-two {
  position: relative;
  z-index: 1;
}
.process-style-one-box.style-two::after {
  position: absolute;
  left: 0;
  top: 25px;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../Images/shape/process.png);
  background-repeat: no-repeat;
  z-index: -1;
  background-size: contain;
  transform: rotate(-5deg);
}
.process-style-one.active .icon {
  height: 200px;
  width: 200px;
  line-height: 200px;
}
.process-style-one.active .icon img {
  height: 80px;
}
.process-style-one.active:nth-child(2n) .icon span {
  height: 55px;
  width: 55px;
  line-height: 55px;
  font-size: 20px;
  top: 20px;
  right: -10px;
}
.process-style-one-box.style-two .icon {
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
}
.pricing-style-two .pricing-header {
  padding: 30px 60px;
  padding-top: 40px;
  text-align: center;
  position: relative;
  z-index: 1;
}
.pricing-style-two.active .pricing-header::after {
  background: var(--color-primary);
}
.pricing-style-two .pricing-header h4 {
  color: var(--white);
  margin-bottom: 0;
}
.pricing-style-two .pricing-header p {
  color: var(--white);
}
.pricing-style-two {
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
  background: var(--white);
}
.pricing-style-two .pricing-content {
  padding: 60px;
  padding-top: 70px;
}
.pricing-style-two .pricing-header h4 {
  text-transform: uppercase;
  font-weight: 600;
}
.pricing-style-two .pricing-header p {
  margin: 0;
  opacity: 0.9;
}
.pricing-style-two .pricing-header::after {
  position: absolute;
  right: -10%;
  top: -20%;
  content: "";
  height: 125%;
  width: 110%;
  background: var(--dark);
  z-index: -1;
  transform: skewY(-6deg);
}
.pricing-style-two .pricing-header::before {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  height: 50px;
  width: 100%;
  background: var(--dark);
  z-index: -1;
  transform: skewY(7deg);
  opacity: 0.1;
}
.pricing-style-two.active .pricing-header::before {
  background: var(--color-primary);
}
.pricing-style-two h2 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 25px;
}
.pricing-style-two h2 sub {
  font-size: 16px;
  font-weight: 500;
  margin-left: -5px;
}
.pricing-style-two h2 sup {
  font-size: 30px;
  top: -18px;
  font-weight: 500;
}
.pricing-style-two h2 strong {
  font-weight: 600;
}
.pricing-style-two li {
  margin-top: 7px;
  font-size: 18px;
}
.pricing-style-two li i {
  font-size: 14px;
  margin-right: 5px;
  position: relative;
  font-weight: 100;
}
.pricing-style-two .pricing-header i {
  display: inline-block;
  color: var(--white);
  font-size: 50px;
  margin-bottom: 20px;
  font-weight: 100;
}
.pricing-style-two .pricing-content .price h2 {
  margin: 0;
  margin-top: 20px;
}
.btn-md.btn-border-dark {
  padding: 13px 52px;
}
@media only screen and (max-width: 1199px) {
  .faq-style-two-items::after {
    right: -100%;
  }
  .faq-style-two-items {
    padding-right: 0;
  }
  .services-style-five > a {
    transform: translateY(0);
    opacity: 1;
  }
  .services-style-five {
    padding-bottom: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (max-width: 991px) {
  .expertise-area .right-info {
    margin-top: 20px;
  }
  .process-style-one-box.style-two::after {
    display: none;
  }
  .process-style-one.active:nth-child(2n) .icon span {
    height: 45px;
    width: 45px;
    line-height: 45px;
    right: -15px;
  }
  .process-style-one.active .icon img {
    height: 60px;
  }
  .process-style-one.active .icon {
    height: 150px;
    width: 150px;
    line-height: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .top-bar .text-end {
    display: none;
  }
  ul.topbar-list {
    display: block;
    text-align: center;
  }
  ul.financial-step {
    grid-column-gap: 50px;
  }
  .banner-style-six-area {
    padding-top: 0;
  }
  .banner-area.double-items.bottom-thumb .content {
    padding-bottom: 50px;
  }
  .banner-style-six {
    text-align: center;
  }
  .banner-area.double-items .thumb img {
    max-width: 50%;
  }
  .about-style-six-counter {
    margin: 0;
    right: 0;
  }
  ul.counter-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
  }
  .about-style-six-area {
    background-image: none !important;
    padding-bottom: 120px;
  }
  .about-style-five.right-border {
    padding: 0;
    border: none;
    margin-top: 40px;
  }
  .business-progress-thumb img {
    max-width: 100%;
    margin-left: -15%;
    margin-bottom: 45px;
  }
  .portfolio-left-heading {
    float: left;
    width: 100%;
    margin-bottom: 50px;
  }
  .portfolio-left-heading {
    float: left;
    width: 100%;
    margin-bottom: 50px;
  }
  .faq-style-two-items .accordion {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .top-bar {
    display: none;
  }
  .banner-style-business h2 {
    font-size: 36px;
  }
  .banner-shape-bg {
    display: none;
  }
  .banner-business .banner-thumb {
    z-index: 1;
  }
  .banner-business .banner-thumb::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 100%;
    background: var(--black);
    z-index: -1;
    opacity: 0.5;
  }
  .banner-style-six-area {
    padding-top: 10px;
  }
  .banner-style-six h2 {
    font-size: 36px;
  }
  .banner-style-six {
    text-align: center;
  }
  .banner-area.double-items .thumb img {
    max-width: 50%;
  }
  ul.financial-step {
    grid-template-columns: 1fr;
  }
  .about-style-five .fun-fact span.medium {
    padding-right: 0;
  }
  .about-style-five .fun-fact .counter {
    font-size: 100px;
  }
  .about-style-five.right-border {
    padding: 0;
    border: none;
    margin-top: 40px;
  }
  ul.financial-step li {
    align-items: center;
  }
  .business-progress-thumb img {
    margin-left: -15%;
    margin-bottom: 40px;
  }
  .business-progress-thumb .fun-fact {
    display: none;
  }
  .about-style-six-area {
    background-image: none !important;
  }
  ul.counter-list {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    grid-row-gap: 30px;
  }
  .about-style-six-counter {
    margin: 0;
    right: 0;
  }
  .about-style-six {
    padding: 50px 30px;
    margin-top: 30px;
  }
  .portfolio-left-heading {
    margin-bottom: 50px;
    float: left;
    width: 100%;
  }
  .portfolio-items.colums-2 .pf-item {
    width: 100%;
  }
  .faq-style-two-items {
    padding: 50px 0;
  }
  .faq-style-two-items .accordion {
    margin-top: 30px;
  }
  .project-style-two-area::after {
    transform: inherit;
    left: 0;
    right: 0;
    width: 100%;
  }
  .project-two-swiper-nav {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    right: auto;
  }
  .project-style-two-area {
    padding-bottom: 100px;
  }
  .project-two-swiper-nav .swiper-pagination {
    width: 100%;
  }
  .services-style-six-area {
    text-align: center;
  }
  .service-six-heading img {
    display: none;
  }
  .service-six-heading {
    margin: 0;
    padding: 50px 30px;
  }
}
@media (max-width: 450px) {
  .list-grid-info {
    grid-template-columns: 1fr;
  }
}
.team-style-three {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}
.team-style-three .info {
  position: absolute;
  left: 30px;
  bottom: 70px;
  background: var(--color-primary);
  right: 30px;
  padding: 20px 30px;
  text-align: center;
  border-radius: 10px;
  transition: all 0.35s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.team-style-three .info span {
  color: var(--white);
}
.team-style-three .info a {
  color: var(--white);
}
.team-style-three .social a {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  margin: 0 2px;
}
.team-style-three:hover .info {
  bottom: 30px;
  visibility: visible;
  opacity: 1;
}
.team-style-three .info h4 {
  margin-bottom: 5px;
}
.team-style-three .info span {
  display: block;
  opacity: 0.9;
  margin-bottom: 10px;
}
